:root {
  /* BDO Colours */
  --charcoal: #333333;
  --paleCharcoal: #e7e7e7;
  --ocean: #02a7e3;
  --slate: #647b90;
  --burgundy: #98002e;
  --emerald: #21918e;
  --gold: #df873a;
  --orange: #ff5724;
  --green: #4cae4f;
  --red: #ed1a3b;
    /* Tints */
  --charcoalLight: #808080;
  --paleCharcoalLight: #fafafa;
  --oceanLight: #ccedf9;
  --slateLight: #dbe1e6;
  --burgundyLight: #f0d9e0;
  --emeraldLight: #eafafa;
  --goldLight: #f5dbc4;
  --goldLightest: #fcf4ee;
  --orangeLight: #ffa58a;
  --greenLight: #91cf93;
  --redLight: #fcd9de;
  /* Shades */
  --charcoalDark: #111111;
  --paleCharcoalDark: #919191;
  --oceanDark: #0282b1;
  --slateDark: #647b90;
  --burgundyDark: #66001f;
  --emeraldDark: #1c7d79;
  --goldDark: #9d5f29;
  --orangeDark: #ff5724;
  --greenDark: #3d8a3f;
  --redDark: #c6102b;
  /* Brand specified */
  --BDO-background: #505050;
  --BDO-Hyperlink: #428bca;

  /* BDO Global */
  --BDO-Red: #ed1a3b;
  --BDO-Red-Light: #fa4258;
  --BDO-Red-Dark: #c70a2e;
  --BDO-Charcoal: #333333;
  --BDO-Slate: #657c91;
  --BDO-Emerald: #218f8b;
  --BDO-Ocean: #02a5e2;
  --BDO-Ocean-Light: #3abeeb;
  --BDO-Ocean-Dark: #0480b8;
  --Success-Green: #009966;
  --Success-Green-Light: #78b28d;
  --Success-Green-Dark: #3a8058;
  --Urgent-Red: #ad1931;
  --Warning-Orange: #fd7e14;
  --Highlight-Grey: #f7f6f7;
  --BG-Grey: #f1f1f3;
  --Mid-Grey: #e5e5ea;
  --Primary-Text: #333333;
  --Secondary-Text: #6f6f6f;
  --Disabled-Text: #959597;
  --Divider: #d4d4d5; /* BDO Colours */
  --charcoal: #333333;
  --paleCharcoal: #e7e7e7;
  --ocean: #02a7e3;
  --slate: #647b90;
  --burgundy: #98002e;
  --emerald: #21918e;
  --gold: #df873a;
  --orange: #ff5724;
  --green: #4cae4f;
  --red: #ed1a3b;
  /* Tints */
  --charcoalLight: #808080;
  --paleCharcoalLight: #fafafa;
  --oceanLight: #ccedf9;
  --slateLight: #dbe1e6;
  --burgundyLight: #f0d9e0;
  --emeraldLight: #eafafa;
  --goldLight: #f5dbc4;
  --goldLightest: #fcf4ee;
  --orangeLight: #ffa58a;
  --greenLight: #91cf93;
  --redLight: #fcd9de;
  /* Shades */
  --charcoalDark: #111111;
  --paleCharcoalDark: #919191;
  --oceanDark: #0282b1;
  --slateDark: #647b90;
  --burgundyDark: #66001f;
  --emeraldDark: #1c7d79;
  --goldDark: #9d5f29;
  --orangeDark: #ff5724;
  --greenDark: #3d8a3f;
  --redDark: #c6102b;
  /* Brand specified */
  --BDO-background: #505050;
  --BDO-Hyperlink: #428bca;
  /* 2023 Rebrand */
  --BDO-Grey: #5b6e7f;
  /* BDO Global */
  --BDO-Red: #ed1a3b;
  --BDO-Red-Light: #fa4258;
  --BDO-Red-Dark: #c70a2e;
  --BDO-Charcoal: #333333;
  --BDO-Slate: #657c91;
  --BDO-Emerald: #218f8b;
  --BDO-Ocean: #02a5e2;
  --BDO-Ocean-Light: #3abeeb;
  --BDO-Ocean-Dark: #0480b8;
  --Success-Green: #009966;
  --Success-Green-Light: #78b28d;
  --Success-Green-Dark: #3a8058;
  --Urgent-Red: #ad1931;
  --Warning-Orange: #fd7e14;
  --Highlight-Grey: #f7f6f7;
  --BG-Grey: #f1f1f3;
  --Mid-Grey: #e5e5ea;
  --Primary-Text: #333333;
  --Secondary-Text: #6f6f6f;
  --Disabled-Text: #959597;
  --Divider: #d4d4d5;

  /* Misc */
  --shade: rgb(51 51 51 / 0.15);
  --tint: rgb(248 248 248 / 0.33);
  --focus: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  --focus-border: 1px solid rgb(66 139 202 / 70%);

  /* BDO custom fonts */
  --fontPrimary: "Proxima Nova Rg", sans-serif;

  /*Survey JS defaults */
  --background-dim-light: var(--Highlight-Grey);
  --background-dim: var(--BG-Grey);
  --background: #fff;
  --base-unit: 7px;
  --blue-light: rgba(67, 127, 217, 0.1);
  --border-inside: rgba(0, 0, 0, 0.16);
  --border-light: var(--Divider);
  --border: var(--paleCharcoalDark);
  --font-family: var(--fontPrimary);
  --foreground-disabled: var(--Disabled-Text);
  --foreground-light: var(--Secondary-Text);
  --foreground: var(--charcoal);
  --green-light: var(--Success-Green-Light);
  --green: var(--Success-Green);
  --primary-foreground: #fff;
  --primary-light: var(--redLight);
  --primary: var(--red);
  --red-light: var(--redLight);
  --sd-base-padding: var(--base-unit);
  --sd-base-vertical-padding: var(--base-unit);
  --secondary-foreground: #fff;
  --secondary-light: var(--goldLight);
  --secondary: var(--gold);
}
