html,
body,
#capture-survey,
[id="root"],
.passcodePage {
  min-height: 100%;
  height: 100%;
}
body,
.passcodePage {
  font-size: var(--base-unit);
  font-family: var(--fontPrimary);
  color: var(--BDO-Charcoal);
  margin: 0;
  line-height: 1.5;
}
.passcodePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  padding: 1.5rem;
  min-height: 400px;
  justify-content: center;
}

.passcodePage p, .passcodePage h2 {
    text-align: center;
}

.passCard,
.capturePasscode,
.captureIntro {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
}

.capturePasscode p {
  width: 70%;
}

.continueWithoutPasscode {
  margin-top: 20px;
}

.passcodePage .passCard {
  background: white;
  border: 1px solid var(--Mid-Grey);
  padding: 3rem;
}
.passcodePage h2 {
  font-size: 1.714rem;
  margin: 0 0 0.5rem;
  font-weight: 600;
}
.passcodePage p {
  margin: 0 auto;
}
.passcodePage form {
  flex: 1;
  width: 100%;
}
.passcodePage form > section {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.passcodePage .form-label {
  font-weight: 600;
  margin-bottom: 0.33em;
}
.passcodePage .form-label:after {
  content: ":";
}

input[type="password"] {
  border: 1px solid var(--Mid-Grey);
  line-height: 100%;
  height: 2.57em;
  padding: 0 5px;
  vertical-align: middle;
  background: var(--Highlight-Grey);
  box-shadow: none;
  color: var(--BDO-Charcoal);
}
.passcodePage input:focus {
  border-color: var(--BDO-Hyperlink);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
    0 0 8px rgb(2 165 226 / 0.3);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(2 165 226 / 0.3);
}
.passcodePage button {
  border: none;
  background-color: var(--BDO-Red);
  color: white;
  padding: 1rem 2rem;
  font-family: var(--fontPrimary);
  transition: 0.3s;
  min-width: 10rem;
  width: 100%;
}
.passcodePage button:hover {
  background-color: var(--Urgent-Red);
  cursor: pointer;
}
input[type="password"],
input[type="text"],
.passcodePage button,
.passcodePage .form-label {
  font-size: calc(var(--base-unit) * 2);
}
.captureIntro {
  padding-bottom: 3rem;
}

.beginSurvey button {
  border: none;
  background-color: var(--BDO-Red);
  color: white;
  padding: 1rem 2rem;
  font-family: var(--fontPrimary);
  transition: 0.3s;
  min-width: 10rem;
  width: 100%;
}

.passcodePage label,
.passcodePage input,
.passcodePage button {
  width: 70%;
  margin: 0 auto;
}
.alert-danger {
  color: var(--Urgent-Red);
}

.alert {
  padding-top: 0.5em;
}

html,
body,
[class*="root"],
.bdo-page {
  min-height: 100%;
  height: 100%;
}
body,
.bdo-page {
  font-size: var(--base-unit);
  font-family: var(--fontPrimary);
  color: var(--BDO-Charcoal);
  margin: 0;
  line-height: 1.5;
}
.bdo-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--BG-Grey);
  padding: 2rem;
  text-align: center;
}

.bdo-page h3 {
  font-size: 1.714rem;
  margin-bottom: 0.5rem;
  display: block;
  font-weight: bold;
}
.bdo-page p {
  font-size: 0.95rem;
  margin: 0 auto 2rem;
  display: block;
}
.bdo-page p a:link {
  color: var(--BDO-hyperlink);
}

.bdo-page .header-image {
  height: 10rem;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  background-position: center;
  min-width: 10rem;
  margin-bottom: 2rem;
}

.bdo-page.success .header-image {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 286 286'%3E%3Cpath fill='%2354a575' fill-rule='evenodd' d='M266 143c0 67.931-55.069 123-123 123S20 210.931 20 143 75.069 20 143 20s123 55.069 123 123Zm20 0c0 78.977-64.023 143-143 143S0 221.977 0 143 64.023 0 143 0s143 64.023 143 143Zm-239-2.079 46.881 63.743 144.282-101.042L222.334 81l-122.206 85.576-30.93-42.03L47 140.921Z' clip-rule='evenodd'/%3E%3C/svg%3E");
}

.bdo-page.error .header-image {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 186 163'%3E%3Cpath xmlns='http://www.w3.org/2000/svg' fill='%2398002E' d='M180.501 162.339H5.774c-1.88 0-3.64-1.033-4.61-2.669-.97-1.638-.97-3.64 0-5.338L88.558 3.012c1.88-3.274 7.278-3.274 9.219 0l87.394 151.32c.97 1.637.97 3.639 0 5.338a5.272 5.272 0 0 1-4.609 2.669h-.061Zm-165.57-10.615h156.352L93.107 16.294l-78.175 135.43Zm87.273-48.64V45.649H84.01v70.171l18.194-12.736ZM84.01 142.688v-13.95l18.194-12.736v26.686H84.01Z'/%3E%3C/svg%3E");
}

.bdo-page footer {
  min-height: 3.313rem;
}
.bdo-page button {
  border: none;
  color: white;
  padding: 1rem 2rem;
  font-size: calc(var(--base-unit) * 2);
  font-family: var(--fontPrimary);
  transition: 0.3s;
}

.bdo-page.error button {
  background-color: var(--Urgent-Red);
}
.bdo-page.success button {
  background-color: var(--Success-Green);
}

.bdo-page button:hover {
  cursor: pointer;
  background-color: var(--BDO-Red);
  box-shadow: 0 2px 5px 0 rgb(51 51 51 / 23%);
}

/* Spinner loader */
.bdo-spinner {
  border: 0.688rem solid transparent;
  border-top: 0.688rem solid var(--BDO-Red);
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bdo-spinner-container {
  display: flex;
  justify-content: center;
}
