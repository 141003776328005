/* Default V2 styles */
.svc-logic-question-value > .sd-question__erbox--above-question,
div:only-child
  > .sd-element--with-frame:not(.sd-element--collapsed)
  > .sd-question__erbox--above-question {
  border-radius: calc(0.5 * var(--base-unit)) calc(0.5 * var(--base-unit)) 0 0;
  margin-left: calc(-1 * var(--sd-base-padding));
  width: calc(100% + 2 * var(--sd-base-padding));
}
.svc-logic-question-value.sd-question--paneldynamic,
div:only-child
  > .sd-element--with-frame:not(
    .sd-element--collapsed
  ).sd-question--paneldynamic {
  padding-bottom: 0;
}
.svc-logic-question-value,
.sd-element:not(.sd-element--collapsed) {
  padding-top: var(--sd-base-vertical-padding);
}
.sd-element__title .svc-string-editor {
  display: inline-block;
  max-width: 100%;
}
.sd-element__title .sv-string-editor {
  max-width: 100%;
  white-space: normal;
}
.sd-element__title .sv-title-actions__title {
  white-space: nowrap;
}
div:not(:only-child) > .sd-element > .sd-question__header .sd-action-bar,
.sd-element__title--expandable .sd-action-bar {
  margin-right: 0;
}
div:not(:only-child) > .sd-element > .sd-question__header .sd-element__num,
.sd-element__title--expandable .sd-element__num {
  float: none;
  margin-left: 0;
  width: auto;
  padding-left: 0;
  padding-right: 0;
}
div:not(:only-child)
  > .sd-element
  > .sd-question__header
  .sd-element__num
  + span,
.sd-element__title--expandable .sd-element__num + span {
  float: none;
  width: auto;
}
.sd-element__title--expandable.sd-element__title--expandable {
  position: relative;
  display: block;
}
.sd-element__title--expandable:before {
  content: "";
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 14L5 13L10 8L5 3L6 2L12 8L6 14Z' fill='%23909090'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(2 * var(--base-unit));
  width: calc(2 * var(--base-unit));
  position: absolute;
  left: calc(-3 * var(--base-unit));
  top: calc(0.5 * var(--base-unit));
}
.sd-element__title--expandable.sd-element__title--expanded:before {
  transform: rotate(90deg);
}
.sd-question {
  position: relative;
}
.sd-question__erbox--above-question {
  margin-top: calc(-1 * var(--sd-base-vertical-padding));
  margin-bottom: calc(var(--sd-base-vertical-padding) - var(--base-unit));
  width: 100%;
  color: var(--red, #e60a3e);
  background-color: var(--red-light, rgba(230, 10, 62, 0.1));
  box-sizing: border-box;
}
.sd-question__erbox {
  padding: calc(1 * var(--base-unit)) calc(1.5 * var(--base-unit));
  border-radius: calc(0.5 * var(--base-unit));
  font-weight: 600;
  line-height: calc(2 * var(--base-unit));
  font-size: calc(1.5 * var(--base-unit));
}
.sd-question__header {
  width: 100%;
}
.sd-scrollable .sd-question__content {
  overflow-x: auto;
  padding: calc(2 * var(--base-unit)) 0;
}
.sd-question__header--location--left {
  display: inline-block;
  width: auto;
  max-width: 50%;
  vertical-align: top;
}
.sd-question__content--left {
  display: inline-block;
  width: calc(50% - 3 * var(--base-unit));
  padding-left: calc(3 * var(--base-unit));
}
.sd-question__required-text {
  color: var(--red, #e60a3e);
  vertical-align: top;
}
.sd-question__form-group {
  margin-top: calc(2 * var(--base-unit));
}
.sd-question__erbox--tooltip {
  display: none;
  position: fixed;
  height: auto;
  width: auto;
  max-width: calc(29 * var(--base-unit));
  background-color: var(--background);
  color: var(--foreground, #161616);
  opacity: 0.75;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  white-space: normal;
  text-align: left;
  z-index: 1000;
}
.sd-question__content:hover:not(:focus-within) > .sd-question__erbox--tooltip {
  display: block;
}
.sd-question__erbox--location--top,
.sd-question__erbox--location--bottom {
  display: none;
}
.sd-question--complex .sd-question__erbox--above-question {
  margin-top: 0;
}
.sd-question--complex
  .sd-question__erbox--above-question
  ~ .sd-question__header--location-top {
  padding-top: calc(1 * var(--base-unit));
}
.sd-question--empty.sd-question--complex > .sd-question__header--location-top {
  padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));
}
.sd-question--empty.sd-question--complex
  > .sd-question__header--location-top:after {
  display: none;
}
.sd-question__placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: calc(0.5 * var(--base-unit));
  justify-content: center;
  min-height: calc(24 * var(--base-unit));
  line-height: calc(3 * var(--base-unit));
  font-size: calc(2 * var(--base-unit));
  color: var(--foreground-light);
}
.sd-question__placeholder > div > .sv-string-viewer,
.sd-question__placeholder > span > .sv-string-viewer {
  white-space: pre-line;
}
.sd-question--empty.sd-question--complex > .sd-question__content {
  padding-top: 0;
  padding-bottom: 0;
}
.sd-question--empty.sd-question--complex > .sd-question__content:first-child {
  padding-top: var(--sd-base-padding);
}
.sd-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: static;
  width: 100%;
  height: calc(6 * var(--base-unit));
  box-sizing: border-box;
  padding: calc(1.5 * var(--base-unit)) calc(2 * var(--base-unit));
  line-height: calc(3 * var(--base-unit));
  font-family: var(--fontPrimary);
  font-size: calc(2 * var(--base-unit));
  color: var(--foreground, #161616);
  background-color: var(--background-dim-light, #f9f9f9);
  box-shadow: inset 0px 0px 0px 1px var(--slateLight);
  border: none;
  border-radius: 0;
}
.sd-input.sd-input:focus {
  outline: none;
  border: none;
}
.sd-input:focus {
  box-shadow: var(--focus);
  border: var(--focus-border);
}
.sd-input--disabled {
  background-color: var(--background-dim-light, #f9f9f9);
  color: var(--foreground-disabled, rgba(22, 22, 22, 0.25));
}
.sd-input::placeholder {
  color: var(--foreground-light);
}
.sd-input--disabled,
.sd-input--disabled::placeholder {
  color: var(--foreground-disabled);
}
.sd-input--error {
  background-color: var(--background-dim-light, #f9f9f9);
}
.sd-comment {
  height: calc(18 * var(--base-unit));
  min-width: calc(6 * var(--base-unit));
  min-height: calc(6 * var(--base-unit));
  max-width: 100%;
}
.sd-panel__title {
  outline: none;
}
.sd-panel {
  position: relative;
}
.sd-panel .sd-row {
  margin-top: calc(1 * var(--base-unit));
}
.sd-panel .sd-row:first-child {
  margin-top: 0;
}
.sd-panel .svc-row .sd-row {
  margin-top: calc(2 * var(--base-unit));
}
.sd-panel__required-text {
  color: var(--red);
}
.sjs_sp_placeholder {
  color: var(--foreground-light);
}
.sjs_sp_container {
  border: 1px dashed var(--border);
  box-sizing: content-box;
  margin: 0;
}
.sjs_sp_controls.sd-signaturepad__controls {
  right: calc(1 * var(--base-unit));
  top: calc(1 * var(--base-unit));
  left: auto;
  bottom: auto;
}
.sd-question--signature:not(.sd-question--answered) .sd-signaturepad__controls {
  display: none;
}
.sd-question--signature.sd-question--error .sjs_sp_placeholder {
  background-color: var(--red-light);
}
.sd-checkbox__decorator {
  border-radius: 3px;
}
.sd-checkbox__svg {
  display: none;
  width: 20px;
  height: 20px;
}
.sd-checkbox--checked .sd-checkbox__svg {
  display: block;
}
.sd-checkbox--checked .sd-checkbox__svg use {
  fill: var(--background);
}
.sd-checkbox--checked.sd-checkbox--disabled .sd-checkbox__svg use {
  fill: var(--border);
}
.sd-checkbox--checked
  .sd-checkbox__control:focus
  + .sd-checkbox__decorator
  .sd-checkbox__svg
  use {
  fill: var(--primary);
}
.sd-matrixdynamic__btn.sd-matrixdynamic__add-btn {
  position: sticky;
  left: calc(-3 * var(--base-unit));
  margin-left: calc(-3 * var(--base-unit));
  z-index: 12;
}
.sd-matrixdynamic__footer:first-child {
  padding-bottom: calc(2 * var(--base-unit));
}
.sd-action.sd-matrixdynamic__remove-btn {
  opacity: 0.5;
}
.sd-matrixdynamic__btn {
  appearance: none;
  background: transparent;
  border: none;
  line-height: calc(3 * var(--base-unit));
  font-size: calc(2 * var(--base-unit));
  font-family: var(--fontPrimary);
  font-weight: 600;
  padding: calc(1 * var(--base-unit)) 0;
}
.sd-matrixdynamic__drag-element {
  padding: calc(2 * var(--base-unit));
}
.sd-matrixdynamic__drag-element:hover {
  background-color: var(--background);
}
.sd-matrixdynamic__drag-element:after {
  content: " ";
  display: block;
  height: calc(0.5 * var(--base-unit));
  width: calc(2 * var(--base-unit));
  border: 1px solid var(--border);
  box-sizing: border-box;
  border-radius: 10px;
}
.sd-matrixdynamic__placeholder .sd-matrixdynamic__add-btn {
  margin-left: 0;
}
.sd-table {
  width: 100%;
  background-color: var(--background);
  border-collapse: collapse;
  white-space: normal;
}
.sd-table__cell {
  font-weight: normal;
  font-size: calc(2 * var(--base-unit));
  line-height: calc(3 * var(--base-unit));
  padding-bottom: calc(2 * var(--base-unit));
  color: var(--foreground);
  text-align: center;
  padding-right: calc(1 * var(--base-unit));
  padding-left: calc(1 * var(--base-unit));
}
.sd-table__cell--header {
  font-weight: 600;
  vertical-align: top;
  padding-top: calc(1.5 * var(--base-unit));
  padding-bottom: calc(3.5 * var(--base-unit));
}
.sd-table__cell--actions .sv-action-bar,
.sd-matrixdynamic__add-btn .sv-action-bar {
  overflow: visible;
}
.sd-table__cell--actions .svc-string-editor__button--done,
.sd-matrixdynamic__add-btn .svc-string-editor__button--done {
  width: calc(2 * var(--base-unit));
}
.svc-question__content .sd-table__cell--actions .sv-action-bar-item:disabled {
  background: var(--background);
  opacity: 1;
}
.svc-question__content
  .sd-table__cell--actions
  .sv-action-bar-item:disabled
  use {
  fill: var(--foreground-light);
}
.sd-table__cell--actions:not(.sd-table__cell--vertical) {
  width: 0;
}
.sd-table__cell--detail-button {
  border: none;
  background: transparent;
  width: calc(4 * var(--base-unit));
  height: calc(4 * var(--base-unit));
  padding: calc(1 * var(--base-unit));
}
.sd-table__cell--detail-button svg {
  width: calc(2 * var(--base-unit));
  height: calc(2 * var(--base-unit));
  fill: var(--foreground-light);
}
.sd-table__cell--actions {
  white-space: nowrap;
}
.sd-table__cell--actions.sd-table__cell--vertical .sd-action-bar {
  justify-content: center;
}
.sd-table__cell--row-text {
  font-weight: 600;
  text-align: left;
}
.sd-table__question-wrapper:not(:focus-within):hover {
  position: relative;
}
.sd-table__question-wrapper:not(:focus-within):hover
  .sd-question__erbox--tooltip {
  display: inline-block;
}
.sd-table__cell--actions:not(.sd-table__cell--vertical),
.sd-table__cell--empty,
.sd-table__cell--row-text,
.sd-matrix__cell:first-child,
.sd-matrix tr > td:first-child {
  position: sticky;
  background: var(--background);
  z-index: 12;
}
.sd-table__cell--actions:not(.sd-table__cell--vertical):first-child,
.sd-table__cell--empty:first-child,
.sd-table__cell--row-text:first-child,
.sd-matrix__cell:first-child:first-child,
.sd-matrix tr > td:first-child:first-child {
  left: calc(-1 * var(--base-unit));
}
.sd-table__cell--actions:not(.sd-table__cell--vertical):last-child,
.sd-table__cell--empty:last-child,
.sd-table__cell--row-text:last-child,
.sd-matrix__cell:first-child:last-child,
.sd-matrix tr > td:first-child:last-child {
  right: calc(-1 * var(--base-unit));
}
.sd-table__cell--actions:not(.sd-table__cell--vertical):last-child
  .sd-action-bar {
  margin-right: calc(-3 * var(--base-unit));
  justify-content: end;
  background: var(--background);
}
.sd-question.sd-question--table {
  position: relative;
  overflow-x: auto;
}
.sd-question--table > .sd-question__content {
  display: flex;
  padding-top: calc(2.5 * var(--base-unit));
  margin: 0 calc(-1 * var(--sd-base-padding));
  width: fit-content;
  min-width: calc(100% + 2 * var(--sd-base-padding));
}
.sd-question--table > .sd-question__content:before,
.sd-question--table > .sd-question__content:after {
  content: "";
  display: block;
  position: sticky;
  min-height: 100%;
  width: calc(var(--sd-base-padding) - var(--base-unit));
  flex-shrink: 0;
  background: var(--background);
  z-index: 11;
}
.sd-question--table > .sd-question__content::before {
  left: calc(-1 * var(--sd-base-padding));
}
.sd-question--table > .sd-question__content:after {
  right: calc(-1 * var(--sd-base-padding));
}
.sd-question--table > .sd-question__content > div {
  flex-basis: 100%;
}
.sd-question--table > .sd-question__header {
  position: sticky;
  left: 0;
}
.sd-question--table:not(.sd-element--with-frame) {
  padding-right: var(--sd-base-padding);
  margin-right: calc(-1 * var(--sd-base-padding));
  padding-left: var(--sd-base-padding);
  margin-left: calc(-1 * var(--sd-base-padding));
  box-sizing: content-box;
}
.sd-page__title {
  position: static;
  font-size: calc(3 * var(--base-unit));
  margin-top: 0 !important;
  /* line-height: calc(4 * var(--base-unit)); */
  /* margin: calc(0.5 * var(--base-unit)) 0px; */
}
.sd-page__description {
  position: static;
  font-size: calc(2 * var(--base-unit));
  line-height: calc(3 * var(--base-unit));
  margin: calc(0.5 * var(--base-unit)) 0px;
}
.sd-page > .sd-row--multiple,
.svc-row > .sd-row--multiple {
  background: var(--background);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: calc(0.5 * var(--base-unit));
}
.sd-page > .sd-row--multiple {
  padding: 0 var(--sd-base-padding) var(--sd-base-padding);
}
.sd-row--multiple {
  gap: 16px;
  margin-top: calc(2 * var(--base-unit));
}
.sd-row--multiple .sd-question {
  padding-right: 0 !important;
}
.sd-row--multiple .sv-vue-row-additional-div {
  display: flex;
  flex-wrap: initial;
  flex-direction: row;
  width: 100%;
  gap: 16px;
}
.svc-row .sd-row--multiple {
  overflow: auto;
  margin-top: calc(2 * var(--base-unit));
}
.svc-row--multiple .sd-row--multiple {
  gap: var(--base-unit);
}
.sd-row__panel {
  box-sizing: border-box;
  width: 100%;
}
.svc-tab-designer .sd-title {
  display: flex;
}
.sd-title.sd-container-modern__title .sd-logo__image {
  margin-top: calc(1 * var(--base-unit));
}
.sd-header__text {
  flex-grow: 1;
}
.sd-header__text h5 {
  font-size: calc(2 * var(--base-unit));
  font-weight: 400;
  line-height: calc(3 * var(--base-unit));
}
.sd-title .sv-title-actions {
  width: 100%;
  justify-content: space-between;
}
.sd-title .sd-action-bar {
  margin: calc(-1 * var(--base-unit)) 0;
  margin-right: calc(-3 * var(--base-unit));
}
.sd-item {
  padding: calc(1.5 * var(--base-unit)) 0;
}
.sd-item--disabled.sd-item--disabled .sd-item__decorator,
.sd-item__decorator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(3 * var(--base-unit));
  height: calc(3 * var(--base-unit));
  box-sizing: border-box;
  box-shadow: inset 0px 1px 2px var(--border-inside);
  background: var(--background-dim-light, #f9f9f9);
  border: none;
  flex-shrink: 0;
}
.sd-item--checked .sd-item__decorator {
  box-shadow: none;
}
.sd-item__control-label {
  font-family: var(--fontPrimary);
  font-style: normal;
  font-weight: normal;
  line-height: calc(3 * var(--base-unit));
  font-size: calc(2 * var(--base-unit));
  margin-left: calc(1 * var(--base-unit));
  color: var(--foreground, #161616);
  white-space: normal;
  word-break: break-word;
}
.sd-item--disabled .sd-item__control-label {
  color: var(--foreground-disabled, rgba(22, 22, 22, 0.25));
}
.sd-item--error .sd-item__decorator {
  background: var(--background-dim-light, #f9f9f9);
}
.sd-selectbase {
  border: none;
  margin: 0;
  padding: 0;
}
.sd-selectbase--multi-column {
  display: flex;
  flex: 1 1 0px;
}
.sd-selectbase__column {
  vertical-align: top;
}
.sd-selectbase__column:not(:last-child) {
  padding-right: 16px;
}
.sd-selectbase__column.sv-q-column-1 {
  width: 100%;
}
.sd-selectbase__other {
  margin-top: calc(2 * var(--base-unit));
}
.sd-selectbase__item .sd-selectbase__other {
  margin-top: calc(3.5 * var(--base-unit));
}
.sd-selectbase__item:last-child .sd-selectbase__other {
  margin-bottom: calc(-2 * var(--base-unit));
}
.sd-checkbox--checked
  .sd-checkbox__control:focus
  + .sd-checkbox__decorator
  .sd-checkbox__svg
  use {
  fill: var(--primary);
}
.sd-radio__decorator {
  border-radius: 50%;
}
.sd-radio--checked .sd-radio__decorator:after {
  content: " ";
  display: block;
  width: calc(1 * var(--base-unit));
  height: calc(1 * var(--base-unit));
  border-radius: 50%;
  background-color: var(--background);
}
.sd-radio--checked.sd-radio--disabled .sd-radio__decorator:after {
  background-color: var(--border, #d6d6d6);
}
.sd-radio--checked .sd-radio__control:focus + .sd-radio__decorator:after {
  background-color: var(--primary);
}
.sd-radio__svg {
  display: none;
}
.sd-visuallyhidden {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}
.sd-matrix fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
.sd-matrix__label {
  display: flex;
  justify-content: center;
}
.sd-matrix__text {
  padding: calc(2 * var(--base-unit));
}
.sd-matrix__text--checked {
  background-color: var(--primary-light, rgba(25, 179, 148, 0.1));
  padding: calc(2 * var(--base-unit));
}
.sd-matrix__cell:first-child {
  font-weight: 600;
  text-align: left;
}
.sd-rating {
  overflow-x: auto;
  height: calc(8 * var(--base-unit));
}
.sd-rating fieldset {
  display: flex;
  border: none;
  padding: 0;
}
.sd-rating__item {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: var(--slateDark);
  width: calc(var(--base-unit) * 6);
  padding: 0;
  position: relative;
  text-align: center;
  transition: 0.3s;
  white-space: nowrap;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23dbe1e6'%3E%3Cpath d='m12 .288 2.833 8.718H24l-7.417 5.389 2.833 8.718L12 17.725l-7.417 5.388 2.833-8.718L0 9.006h9.167z'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: calc(var(--base-unit) * 1.75);
  height: calc(var(--base-unit) * 6);
  padding-top: calc(var(--base-unit) * 0.25 + 2px);
  font-weight: 600;
  border: none;
  box-shadow: none;
}
.sd-rating__item--error {
  background-color: var(--slate,#fff);
  box-shadow: 0px 1px 2px transparent;
  border-color: transparent;
}
.sd-rating__item.sd-rating__item--disabled {
  color: var(--foreground-disabled, rgba(22, 22, 22, 0.25));
}
.sd-rating__item--selected {
  background-color: transparent;
  border: none;
  color: var(--background);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ed1a3b'%3E%3Cpath d='m12 .288 2.833 8.718H24l-7.417 5.389 2.833 8.718L12 17.725l-7.417 5.388 2.833-8.718L0 9.006h9.167z'/%3E%3C/svg%3E");
}
.sd-rating__item + .sd-rating__item {
  margin-left: calc(1 * var(--base-unit));
}
.sd-rating__item-text.sd-rating__item-text {
  line-height: calc(3 * var(--base-unit));
}
.sd-rating__item-text.sd-rating__item-text.sd-rating__min-text,
.sd-rating__item-text.sd-rating__item-text.sd-rating__max-text {
  margin-right: calc(2 * var(--base-unit));
  margin-top: calc(1.25 * var(--base-unit));
  margin-bottom: calc(1.25 * var(--base-unit));
  margin-left: calc(2 * var(--base-unit));
  border: 2px solid var(--background);
  color: var(--foreground-light);
}
.sd-rating__item-text.sd-rating__item-text .sv-string-editor {
  white-space: nowrap;
}
.sv-ranking-item--error .sv-ranking-item__index {
  background-color: var(--red-light);
  box-shadow: 0px 1px 2px transparent;
  border-color: transparent;
}
.sd-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: calc(6 * var(--base-unit));
  background-position: right calc(1.5 * var(--base-unit)) top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: calc(3 * var(--base-unit)) calc(3 * var(--base-unit));
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 15L17 10H7L12 15Z' fill='%23909090'/%3E%3C/svg%3E%0A");
  opacity: 1;
  position: relative;
}
.sd-dropdown--empty {
  color: var(--foreground-light);
}
.sd-dropdown option {
  color: var(--foreground, #161616);
  font-family: var(--fontPrimary);
  font-size: calc(2 * var(--base-unit));
}
.sd-imagepicker {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  border: none;
  gap: calc(2 * var(--base-unit));
  width: 100%;
  margin: 0;
}
.sd-imagepicker__item img {
  border-radius: calc(0.5 * var(--base-unit));
}
.sd-imagepicker__item-decorator {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sd-imagepicker__image-container {
  position: relative;
}
.sd-imagepicker__item--checked .sd-imagepicker__image-container::before {
  display: block;
  position: absolute;
  content: " ";
  top: 8px;
  right: 8px;
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border-radius: 100px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='17' fill='none'%3E%3Cpath d='m8.333 16.1-8-8.1 2.1-2.1 5.9 6L20.233 0l2.1 2.1-14 14Z' fill='%2319B394'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 22px 16px;
  background-color: white;
}
.sd-imagepicker__item--error .sd-imagepicker__image-container::before {
  display: block;
  position: absolute;
  content: " ";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--red-light, rgba(230, 10, 62, 0.1));
  background: linear-gradient(
    0deg,
    var(--red-light, rgba(230, 10, 62, 0.1)),
    var(--red-light, rgba(230, 10, 62, 0.1))
  );
}
.sd-imagepicker__item--allowhover .sd-imagepicker__image:hover {
  opacity: 0.5;
}
.sd-imagepicker__image {
  display: block;
  box-sizing: border-box;
  max-width: 100%;
}
.sd-imagepicker__text {
  line-height: 24px;
  margin-top: 8px;
  color: var(--foreground, #161616);
}
.sd-imagepicker__item.sv-q-col-1 {
  width: 100%;
}
.sd-imagepicker__item.sv-q-col-2 {
  width: calc(50% - (1 * var(--base-unit)));
}
.sd-imagepicker__item.sv-q-col-3 {
  width: calc(33% - (4 / 3 * var(--base-unit)));
}
.sd-imagepicker__item.sv-q-col-4 {
  width: calc(25% - (6 / 4 * var(--base-unit)));
}
.sd-imagepicker__item.sv-q-col-5 {
  width: calc(20% - (8 / 5 * var(--base-unit)));
}
.sd-image__image {
  display: block;
  max-width: 100%;
  border-radius: 4px;
}
.sd-progress {
  height: 0.19em;
  background-color: var(--background-dim);
  position: relative;
}
.sd-progress__bar {
  position: relative;
  height: 100%;
  background-color: var(--primary);
  overflow: hidden;
}
.sd-progress__text {
  position: absolute;
  margin-top: 0.69em;
  color: var(--foreground-light);
  font-size: 0.87em;
  font-weight: bold;
}
.sd-boolean {
  display: flex;
  width: max-content;
  position: relative;
  padding: calc(0.5 * var(--base-unit));
  background: var(--background-dim-light);
  box-shadow: inset 0px 0px 0px 1px var(--slateLight);
  border-radius: calc(12.5 * var(--base-unit));
}
.sd-boolean__thumb,
.sd-boolean__label {
  display: block;
  color: var(--foreground-light);
  font-family: var(--fontPrimary);
  font-size: calc(2 * var(--base-unit));
  line-height: calc(3 * var(--base-unit));
  padding: calc(1 * var(--base-unit)) calc(3 * var(--base-unit));
}
.sd-boolean__switch {
  display: flex;
  padding: calc(0.5 * var(--base-unit));
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: calc(12.5 * var(--base-unit));
}
.sd-boolean__control:focus ~ .sd-boolean__switch {
  box-shadow: var(--focus);
  border: var(--focus-border);
}
.sd-boolean--indeterminate .sd-boolean__thumb {
  display: none;
}
.sd-boolean__thumb {
  background: var(--primary);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: calc(12.5 * var(--base-unit));
  transition-duration: 0.2s;
  transition-property: margin-left, transform;
  transition-timing-function: linear;
  color: white;
  font-weight: 600;
}
.sd-boolean--checked .sd-boolean__thumb {
  margin-left: 100%;
  transform: translateX(-100%);
}
.sd-boolean--disabled .sd-boolean__thumb,
.sd-boolean--disabled .sd-boolean__label {
  color: var(--foreground-disabled, rgba(22, 22, 22, 0.25));
}
.svc-question__content
  .sd-boolean:not(.sd-boolean--checked):not(.sd-boolean--indeterminate)
  .sd-boolean__label:nth-of-type(1),
.svc-question__content .sd-boolean--checked .sd-boolean__label:nth-of-type(2) {
  color: var(--primary);
  font-weight: 600;
  background: var(--background);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: calc(12.5 * var(--base-unit));
}
.svc-question__content .sd-boolean__switch {
  display: none;
}
.sd-boolean--error {
  background-color: var(--red-light, rgba(230, 10, 62, 0.1));
}
.sd-paneldynamic .sd-progress {
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  z-index: 2;
}
.sd-paneldynamic > .sd-panel {
  padding-top: 1px;
  padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));
}
.sd-paneldynamic
  .sd-paneldynamic__panel-wrapper
  > .sd-panel
  > .sd-panel__header {
  padding-bottom: 0;
  padding-top: calc(0.5 * var(--sd-base-vertical-padding) + var(--base-unit));
}
.sd-paneldynamic
  .sd-paneldynamic__panel-wrapper
  > .sd-panel
  > .sd-panel__header:after {
  display: none;
}
.sd-paneldynamic
  .sd-paneldynamic__panel-wrapper
  > .sd-panel
  > .sd-panel__header
  > .sd-panel__title {
  color: var(--foreground-light);
}
.sd-paneldynamic
  .sd-paneldynamic__panel-wrapper
  > .sd-panel
  > .sd-panel__header
  ~ .sd-panel__content {
  margin-top: 8px;
}
.sd-question__content:first-child
  > .sd-paneldynamic
  > .sd-paneldynamic__panel-wrapper
  > .sd-panel
  > .sd-panel__header {
  padding-top: var(--sd-base-vertical-padding);
}
.sd-paneldynamic__separator {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0;
  border-color: #eaeaea;
  background: #eaeaea;
  height: 1px;
  border: none;
}
.sd-paneldynamic__panel-wrapper {
  padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));
}
.sd-paneldynamic__panel-wrapper:after {
  display: table;
  clear: both;
  content: " ";
}
.sd-paneldynamic__panel-wrapper--in-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sd-paneldynamic__btn {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: var(--background);
  font-family: var(--fontPrimary);
  font-weight: 600;
  font-style: normal;
  font-size: calc(2 * var(--base-unit));
  line-height: calc(3 * var(--base-unit));
  padding: calc(1 * var(--base-unit)) calc(3 * var(--base-unit));
  cursor: pointer;
  border-radius: calc(12.5 * var(--base-unit));
}
.sd-paneldynamic__remove-btn {
  float: right;
  margin-top: calc(0.5 * var(--sd-base-vertical-padding));
  margin-right: calc(-3 * var(--base-unit));
}
.sd-paneldynamic__footer {
  clear: both;
}
.sd-paneldynamic__footer .sd-paneldynamic__prev-btn,
.sd-paneldynamic__footer .sd-paneldynamic__next-btn {
  display: block;
}
.sd-paneldynamic__footer .sd-paneldynamic__prev-btn svg,
.sd-paneldynamic__footer .sd-paneldynamic__next-btn svg {
  width: calc(2 * var(--base-unit));
  height: calc(2 * var(--base-unit));
}
.sd-paneldynamic__footer .sd-paneldynamic__add-btn,
.sd-paneldynamic__footer .sd-paneldynamic__progress-text,
.sd-paneldynamic__footer .sd-paneldynamic__progress--bottom {
  display: initial;
}
.sd-paneldynamic__buttons-container {
  display: flex;
  align-items: center;
  padding: calc(var(--sd-base-vertical-padding) - var(--base-unit)) 0;
}
.sd-paneldynamic__add-btn {
  display: none;
}
.sd-paneldynamic__progress-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: calc(-1 * var(--base-unit));
}
.sd-paneldynamic__progress-text {
  display: none;
  color: var(--foreground-light);
  margin: 0 calc(2 * var(--base-unit));
}
.sd-paneldynamic__progress--bottom {
  display: none;
}
.sd-paneldynamic__prev-btn,
.sd-paneldynamic__next-btn {
  display: none;
  width: 16px;
  height: 16px;
}
.sd-paneldynamic__prev-btn .sv-svg-icon,
.sd-paneldynamic__next-btn .sv-svg-icon {
  display: block;
}
.sd-paneldynamic__next-btn {
  transform: rotate(180deg);
}
.sd-paneldynamic__placeholder .sd-paneldynamic__add-btn {
  display: initial;
  margin-left: 0;
}
.sd-question--empty.sd-question--paneldynamic > .sd-question__content {
  padding-bottom: var(--sd-base-padding);
}
.svc-question__content .sd-paneldynamic__panel-wrapper {
  padding: 0;
}
.sd-file {
  min-height: calc(36 * var(--base-unit));
  position: relative;
  font-size: calc(2 * var(--base-unit));
  line-height: calc(3 * var(--base-unit));
}
.sd-file .sv-action-bar {
  padding: calc(1 * var(--base-unit)) 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.sd-file .sv-action-bar .sv-action-bar-item {
  height: calc(4 * var(--base-unit));
  line-height: calc(2 * var(--base-unit));
  color: var(--foreground-light);
  border-radius: calc(2 * var(--base-unit));
}
.sd-file .sv-action-bar #fileIndex .sv-action-bar-item:hover {
  background-color: var(--background);
}
.sd-file .sv-action-bar #fileIndex .sv-action-bar-item:disabled {
  opacity: initial;
}
.sd-file .sv-action-bar .sv-action:not(:last-child) > .sv-action__content {
  padding-right: calc(1 * var(--base-unit));
}
.sd-file__decorator {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px dashed var(--border);
  justify-content: center;
  align-items: center;
}
.sd-file__decorator--drag {
  border: 1px solid var(--primary);
  box-shadow: inset 0 0 0 1px var(--primary);
  background: var(--primary-light, rgba(25, 179, 148, 0.1));
}
.sd-file__decorator--drag:after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: transparent;
  z-index: 2;
}
.sd-file__no-file-chosen {
  display: none;
}
.sd-file__drag-area-placeholder {
  word-break: break-word;
  white-space: normal;
  color: var(--foreground-light);
  display: block;
  padding: 0 1rem;
  text-align: center;
  max-width: 80%;
}
.sd-file__choose-btn--text {
  display: block;
  margin-top: calc(1 * var(--base-unit));
  font-weight: 600;
  color: var(--primary);
  cursor: pointer;
}
.sd-question--answered .sd-file__drag-area-placeholder {
  display: none;
}
.sd-file__choose-btn--text svg {
  display: none;
}
.sd-file__choose-btn--icon {
  z-index: 2;
}
.sd-file__choose-btn--icon span:first-child {
  display: none;
}
.sd-file__clean-btn {
  right: calc(1 * var(--base-unit));
}
.sd-file__clean-btn span:first-child {
  display: none;
}
.sd-file__list {
  overflow: auto;
  display: flex;
  box-sizing: content-box;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  padding: calc(10.5 * var(--base-unit)) 0;
  min-height: calc(15 * var(--base-unit));
  max-height: calc(15 * var(--base-unit));
  position: absolute;
  width: 100%;
}
.sd-file__preview {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
  margin: 0 auto;
}
.sd-file__preview .sd-file__default-image {
  width: calc(7 * var(--base-unit));
  height: 90px;
}
.sd-file__preview img:hover + .sd-file__remove-file-button,
.sd-file__preview svg:hover + .sd-file__remove-file-button,
.sd-file__preview .sd-file__remove-file-button:hover {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.sd-file__sign {
  margin-top: calc(1 * var(--base-unit));
  text-align: center;
  font-size: 12px;
  line-height: 16px;
}
.sd-file__sign a {
  display: block;
  text-decoration: none;
  color: var(--foreground, #161616);
  white-space: normal;
  word-break: normal;
  width: 96px;
}
.sd-file__remove-file-button {
  display: none;
}
.sd-file__decorator--error {
  background-color: var(--red-light, rgba(230, 10, 62, 0.1));
}
.sd-file__image-wrapper {
  position: relative;
  text-align: center;
  min-width: 96px;
  min-height: 96px;
}
.sd-file__image-wrapper img:not(.sd-file__default-image) {
  display: block;
  width: 96px;
  height: 96px;
  object-fit: contain;
  background: #f3f3f3;
}
.sd-file--single img:hover + .sd-file__remove-file-button {
  display: none;
}
.sd-file--mobile .sd-file__list {
  height: calc(100% - 4 * var(--base-unit));
}
.sd-file--single-image {
  height: calc(36 * var(--base-unit));
}
.sd-file--single-image .sd-file__list {
  padding: 0;
  height: 100%;
  max-height: 100%;
  width: 100%;
}
.sd-file--single-image .sd-file__image-wrapper {
  min-height: 100%;
  min-width: 100%;
}
.sd-file--single-image .sd-file__image-wrapper img {
  width: 100%;
  height: 100%;
  background-color: var(--background-dim-light, #f9f9f9);
}
.sd-file--single-image .sd-file__sign {
  position: absolute;
  margin: 0;
  width: 100%;
  height: 100%;
}
.sd-file--single-image .sd-file__sign a {
  color: transparent;
  width: 100%;
  height: 100%;
}
.sd-hidden {
  display: none !important;
}
.sd-btn {
  padding: calc(1.5 * var(--base-unit)) calc(6 * var(--base-unit));
  background: var(--foreground);
  box-shadow: none;
  border-radius: 0;
  cursor: pointer;
  font-family: var(--fontPrimary);
  font-style: normal;
  font-weight: 700;
  font-size: calc(2 * var(--base-unit));
  line-height: calc(3 * var(--base-unit));
  text-align: center;
  color: var(--background);
  border: none;
  outline: none;
  transition: 0.3s;
}
.sd-btn:disabled {
  color: var(--foreground-disabled);
  cursor: default;
}
.sd-btn:disabled:hover {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}
.sd-btn--action {
  background-color: var(--primary);
  border: none;
  color: var(--primary-foreground);
}
.sd-btn--action:disabled {
  background-color: var(--background-dim);
}
.sd-body.sd-body--responsive {
  max-width: initial;
}
.sd-body__navigation {
  padding: calc(2 * var(--base-unit));
}
.sd-body__progress {
  margin: calc(4 * var(--base-unit)) calc(2 * var(--base-unit));
}
/* .sd-body__progress ~ div > .sd-body__page,
.sd-body__progress ~ .sd-body__page {
  margin-top: calc(-4 * var(--base-unit));
} */
.sd-multipletext {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  height: 1px;
}
.sd-multipletext__cell {
  height: 100%;
}
.sd-multipletext__cell:not(:last-child) {
  padding-right: calc(2 * var(--base-unit));
}
.sd-multipletext__item-container.sd-input:focus-within {
  box-shadow: var(--focus);
  border: var(--focus-border);
}
.sd-multipletext__item-container {
  display: flex;
  align-items: flex-start;
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
}
.sd-multipletext__item-container .sd-input {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  border: none;
  box-shadow: none;
  height: calc(3 * var(--base-unit));
}
.sd-multipletext__item-container .sd-input,
.sd-multipletext__item-title {
  margin-top: calc(1.5 * var(--base-unit));
  margin-bottom: calc(1.5 * var(--base-unit));
}
.sd-multipletext__item-title {
  height: calc(100% - var(--base-unit) * 3);
  max-width: 30%;
  padding-right: calc(2 * var(--base-unit));
  border-right: 1px solid var(--border, #d6d6d6);
  word-break: break-word;
  white-space: normal;
  color: var(--foreground-light);
}
.sd-multipletext__item {
  flex-grow: 1;
}
.sd-multipletext__item-container--error {
  background-color: var(--red-light, rgba(230, 10, 62, 0.1));
}
.sd-multipletext__item-container--error .sd-input--error {
  background-color: transparent;
}
.sd-multipletext__item-container:hover:not(:focus-within)
  > .sd-question__erbox--tooltip {
  display: inline-block;
}
.sd-multipletext tr:not(:last-child) .sd-multipletext__cell {
  padding-bottom: calc(2 * var(--base-unit));
}
.sd-action.sd-action--negative {
  color: var(--red);
}
.sd-action.sd-action--disabled {
  color: var(--foreground, #161616);
  opacity: 0.25;
}
.sd-action.sd-action--icon {
  padding: calc(1 * var(--base-unit));
}
.sd-action.sd-action--icon use {
  fill: var(--foreground-light);
}
svg.sd-action--icon {
  fill: var(--foreground-light);
}
.sd-action.sd-action:not(:disabled):not(.sd-action--disabled):hover,
.sd-action.sd-action:not(:disabled):not(.sd-action--disabled):focus-visible {
  outline: none;
  background-color: var(--primary-light, rgba(25, 179, 148, 0.1));
  cursor: pointer;
  opacity: 1;
}
.sd-action.sd-action:not(:disabled):not(
    .sd-action--disabled
  ):hover.sd-action--icon,
.sd-action.sd-action:not(:disabled):not(
    .sd-action--disabled
  ):focus-visible.sd-action--icon {
  background-color: var(--background-dim);
}
.sd-action.sd-action:not(:disabled):not(
    .sd-action--disabled
  ):hover.sd-action--negative,
.sd-action.sd-action:not(:disabled):not(
    .sd-action--disabled
  ):focus-visible.sd-action--negative {
  background-color: var(--red-light, rgba(230, 10, 62, 0.1));
}
.sd-action.sd-action:not(:disabled):not(
    .sd-action--disabled
  ):hover.sd-action--negative.sd-action--icon
  use,
.sd-action.sd-action:not(:disabled):not(
    .sd-action--disabled
  ):focus-visible.sd-action--negative.sd-action--icon
  use {
  fill: var(--red, #e60a3e);
}
.sd-action.sd-action:not(:disabled):not(.sd-action--disabled):hover:active,
.sd-action.sd-action:not(:disabled):not(
    .sd-action--disabled
  ):focus-visible:active {
  opacity: 0.5;
}
.sd-action__icon {
  display: block;
  width: 24px;
  height: 24px;
}
.sd-action-bar {
  display: flex;
  gap: calc(var(--base-unit) * 2);
}
.sd-context-btn {
  background-color: var(--background);
  padding: calc(1.5 * var(--base-unit));
  border-radius: calc(12.5 * var(--base-unit));
  border: none;
  cursor: pointer;
}
.sd-context-btn svg {
  display: block;
  width: 24px;
  height: 24px;
}
.sd-context-btn use {
  fill: var(--foreground-light);
}
.sd-context-btn:focus-visible use,
.sd-context-btn:hover use {
  fill: var(--primary);
}
.sd-context-btn:focus-visible.sd-context-btn--negative use,
.sd-context-btn:hover.sd-context-btn--negative use {
  fill: var(--red, #e60a3e);
}
.sd-context-btn:disabled {
  opacity: 0.25;
}
.sd-completedpage {
  font-family: var(--fontPrimary);
  font-weight: bold;
  box-sizing: border-box;
  text-align: center;
  height: auto;
}
.sd-completedpage h3 {
  font-size: calc(3 * var(--base-unit));
}
.sd-completedpage:before {
  height: calc(9 * var(--base-unit));
}
.sd-completedpage:after {
  height: calc(2 * var(--base-unit));
}
.sd-progress-buttons__container-center {
  text-align: center;
}
.sd-progress-buttons__container {
  display: inline-block;
  font-size: 0;
  width: 100%;
  max-width: 1100px;
  white-space: nowrap;
  overflow: hidden;
}
.sd-progress-buttons__image-button-left {
  display: inline-block;
  vertical-align: top;
  margin-top: 22px;
  font-size: 14px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwb2x5Z29uIHBvaW50cz0iMTEsMTIgOSwxNCAzLDggOSwyIDExLDQgNyw4ICIvPg0KPC9zdmc+DQo=);
}
.sd-progress-buttons__image-button-right {
  display: inline-block;
  vertical-align: top;
  margin-top: 22px;
  font-size: 14px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwb2x5Z29uIHBvaW50cz0iNSw0IDcsMiAxMyw4IDcsMTQgNSwxMiA5LDggIi8+DQo8L3N2Zz4NCg==);
}
.sd-progress-buttons__image-button--hidden {
  visibility: hidden;
}
.sd-progress-buttons__list-container {
  max-width: calc(100% - 36px);
  display: inline-block;
  overflow: hidden;
}
.sd-progress-buttons__list {
  display: inline-block;
  width: max-content;
  padding-left: 28px;
  padding-right: 28px;
  margin-top: 14px;
  margin-bottom: 14px;
}
.sd-progress-buttons__list li {
  width: 138px;
  font-size: 14px;
  font-family: var(--fontPrimary);
  position: relative;
  text-align: center;
  vertical-align: top;
  display: inline-block;
}
.sd-progress-buttons__list li:before {
  width: 24px;
  height: 24px;
  content: "";
  line-height: 30px;
  display: block;
  margin: 0 auto 10px auto;
  border: 3px solid;
  border-radius: 50%;
  box-sizing: content-box;
  cursor: pointer;
}
.sd-progress-buttons__list li:after {
  width: 73%;
  height: 3px;
  content: "";
  position: absolute;
  top: 15px;
  left: -36.5%;
}
.sd-progress-buttons__list li:first-child:after {
  content: none;
}
.sd-progress-buttons__list .sd-progress-buttons__page-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}
.sd-progress-buttons__list .sd-progress-buttons__page-description {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sd-progress-buttons__list
  li.sd-progress-buttons__list-element--nonclickable:before {
  cursor: not-allowed;
}
.sd-progress-buttons__list li.sd-progress-buttons__list-element--passed:before {
  border-color: var(--primary);
  background-color: var(--primary);
}
.sd-progress-buttons__list
  li.sd-progress-buttons__list-element--passed
  + li:after {
  background-color: #8dd9ca;
}
.sd-progress-buttons__list
  li.sd-progress-buttons__list-element--current:before {
  border-color: var(--primary);
  background-color: var(--background);
}
.sd-progress-buttons__list
  li.sd-progress-buttons__list-element--passed.sd-progress-buttons__list-element--current:before {
  border-color: var(--primary);
  background-color: var(--background);
}
.sv-action-bar {
  display: flex;
  box-sizing: content-box;
  position: relative;
  align-items: center;
  margin-left: auto;
  padding: 0 calc(2 * var(--base-unit));
  overflow: hidden;
  white-space: nowrap;
}
.sv-action--first {
  margin-right: auto;
  flex: 1;
}
.sv-action--first .sv-action-bar-item {
  flex-direction: row-reverse;
}
.sv-action--first .sv-action-bar-item__title {
  margin-left: 0;
  margin-right: calc(1 * var(--base-unit));
}
.sv-action-bar-separator {
  display: inline-block;
  width: 1px;
  height: 24px;
  vertical-align: middle;
  margin-right: 16px;
  background-color: var(--border, #d6d6d6);
}
.sv-action-bar-item {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  height: calc(5 * var(--base-unit));
  padding: calc(1 * var(--base-unit));
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  line-height: calc(3 * var(--base-unit));
  font-size: calc(2 * var(--base-unit));
  font-family: var(--fontPrimary);
  overflow-x: hidden;
  white-space: nowrap;
}
button.sv-action-bar-item {
  overflow: hidden;
}
.sv-action-bar-item__icon svg {
  display: block;
}
.sv-action-bar-item__icon use {
  fill: var(--foreground-light);
}
.sv-action-bar-item:hover,
.sv-action-bar-item:focus-visible {
  outline: none;
  background-color: var(--background-dim);
}
.sv-action-bar-item:active {
  opacity: 0.5;
}
.sv-action-bar-item:disabled {
  opacity: 0.25;
  cursor: default;
  color: var(--foreground, #161616);
}
.sv-action-bar-item:disabled .sv-action-bar-item__icon use {
  fill: var(--foreground, #161616);
}
.sv-action-bar-item__title {
  vertical-align: middle;
  white-space: nowrap;
}
.sv-action-bar-item--secondary .sv-action-bar-item__icon use {
  fill: var(--secondary, #ff9814);
}
.sv-action-bar-item--active .sv-action-bar-item__icon use {
  fill: var(--primary);
}
.sv-action-bar-item-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  height: calc(5 * var(--base-unit));
  padding: calc(1 * var(--base-unit));
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  line-height: calc(3 * var(--base-unit));
  font-size: calc(2 * var(--base-unit));
  font-family: var(--fontPrimary);
}
.sv-action-bar-item__title--with-icon {
  margin-left: calc(1 * var(--base-unit));
}
.sv-action--last {
  flex: 1;
  justify-content: flex-end;
  display: flex;
}
.sv-expand-action:before {
  content: "";
  display: inline-block;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='https://protect-eu.mimecast.com/s/D-5wCVvwLsV9JOgTJkAFn?domain=w3.org' xmlns:xlink='https://protect-eu.mimecast.com/s/NX-hCWPxMc1GElDTmaLLY?domain=w3.org' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  height: 10px;
  width: 12px;
  margin: auto 8px;
}
.sv-expand-action--expanded:before {
  transform: rotate(180deg);
}
.sv-dots {
  width: 48px;
}
.sv-dots__item {
  width: 100%;
}
.sv-dots__item .sv-action-bar-item__icon {
  margin: auto;
}
.sv-action__content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sv-action__content > * {
  flex: 0 0 auto;
}
.sv-action:not(:last-child) > .sv-action__content {
  box-sizing: content-box;
  padding-right: calc(2 * var(--base-unit));
}
sv-popup {
  display: block;
  position: absolute;
  z-index: -1;
}
.sv-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  outline: none;
}
.sv-popup--modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sv-popup--modal .sv-popup__container {
  position: static;
  border-radius: calc(0.5 * var(--base-unit));
}
.sv-popup--modal .sv-popup_shadow {
  padding: calc(4 * var(--base-unit));
}
.sv-popup--overlay {
  width: 100%;
}
.sv-popup--overlay .sv-popup__container {
  max-width: 100vw;
  max-height: calc(100vh - 1 * var(--base-unit));
  height: calc(100vh - 1 * var(--base-unit));
  width: 100vw;
  margin-top: calc(2 * var(--base-unit));
  border: unset;
  box-shadow: 0 calc(-4 * var(--base-unit)) 0 0 var(--foreground-disabled);
  border-radius: calc(2 * var(--base-unit)) calc(2 * var(--base-unit)) 0px 0px;
}
.sv-popup--overlay .sv-popup__header {
  padding: calc(2 * var(--base-unit));
}
.sv-popup--overlay .sv-popup__footer {
  padding: calc(2 * var(--base-unit));
  margin-top: calc(1 * var(--base-unit));
}
.sv-popup--overlay .sv-popup__footer .sv-popup__footer-item {
  width: 100%;
}
.sv-popup--overlay .sv-popup__button--cancel {
  background-color: var(--primary);
  border: 2px solid var(--primary);
  color: var(--primary-foreground);
}
.sv-popup__container {
  position: absolute;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
  padding: 0;
}
.sv-popup_shadow {
  width: 100%;
  height: 100%;
  filter: drop-shadow(
    0px calc(1 * var(--base-unit)) calc(2 * var(--base-unit)) rgba(0, 0, 0, 0.1)
  );
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  max-width: 90vw;
}
.sv-popup__scrolling-content {
  height: 100%;
  overflow: auto;
}
.sv-popup__scrolling-content::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: var(--background-dim);
}
.sv-popup__scrolling-content::-webkit-scrollbar-thumb {
  background: var(--primary-light, rgba(25, 179, 148, 0.1));
}
.sv-popup__content {
  min-width: 100%;
}
.sv-popup--show-pointer.sv-popup--top .sv-popup__pointer {
  transform: translate(calc(-1 * var(--base-unit))) rotate(180deg);
}
.sv-popup--show-pointer.sv-popup--bottom .sv-popup__pointer {
  transform: translate(
    calc(-1 * var(--base-unit)),
    calc(-1 * var(--base-unit))
  );
}
.sv-popup--show-pointer.sv-popup--right {
  transform: translate(calc(1 * var(--base-unit)));
}
.sv-popup--show-pointer.sv-popup--right .sv-popup__pointer {
  transform: translate(-12px, -4px) rotate(-90deg);
}
.sv-popup--show-pointer.sv-popup--left {
  transform: translate(calc(-1 * var(--base-unit)));
}
.sv-popup--show-pointer.sv-popup--left .sv-popup__pointer {
  transform: translate(-4px, -4px) rotate(90deg);
}
.sv-popup__pointer {
  display: block;
  position: absolute;
}
.sv-popup__pointer:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-left: calc(1 * var(--base-unit)) solid transparent;
  border-right: calc(1 * var(--base-unit)) solid transparent;
  border-bottom: calc(1 * var(--base-unit)) solid var(--background);
  align-self: center;
}
.sv-popup__header {
  font-family: var(--fontPrimary);
  font-size: calc(3 * var(--base-unit));
  line-height: calc(4 * var(--base-unit));
  font-style: normal;
  font-weight: 700;
  margin-bottom: calc(2 * var(--base-unit));
  color: var(--foreground, #161616);
}
.sv-popup__footer {
  display: flex;
  margin-top: calc(4 * var(--base-unit));
}
.sv-popup__footer-item:first-child {
  margin-left: auto;
}
.sv-popup__footer-item + .sv-popup__footer-item {
  margin-left: calc(1 * var(--base-unit));
}
.sv-popup__button {
  padding: calc(2 * var(--base-unit)) calc(6 * var(--base-unit));
  background: var(--background);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 2px;
  cursor: pointer;
  font-family: var(--fontPrimary);
  font-style: normal;
  font-weight: 600;
  font-size: calc(2 * var(--base-unit));
  line-height: calc(3 * var(--base-unit));
  text-align: center;
  color: var(--primary);
  border: none;
  outline: none;
}
.sv-popup__button:hover {
  box-shadow: 0 0 0 2px var(--primary);
}
.sv-popup__button:disabled {
  color: var(--foreground-disabled);
  cursor: default;
}
.sv-popup__button:disabled:hover {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}
.sv-popup__button--apply {
  background-color: var(--primary);
  color: var(--primary-foreground);
}
.sv-popup__button--apply:disabled {
  background-color: var(--background-dim);
}
.sv-list__item:hover,
.sv-list__item:focus {
  background-color: var(--background-dim);
  outline: none;
}
.sv-list__item-icon {
  float: left;
  width: calc(3 * var(--base-unit));
  height: calc(3 * var(--base-unit));
  margin-right: calc(2 * var(--base-unit));
}
.sv-list__item-icon svg {
  display: block;
}
.sv-list__item-icon use {
  fill: var(--foreground-light);
}
.sv-list__item--selected {
  background-color: var(--primary);
  color: var(--background);
}
.sv-list__item--selected:hover,
.sv-list__item--selected:focus {
  background-color: var(--primary);
  color: var(--background);
}
.sv-list__item--selected .sv-list__item-icon use {
  fill: var(--background);
}
.sv-list__item--disabled {
  color: var(--foreground-disabled);
  cursor: default;
  pointer-events: none;
}
.sv-list__item span {
  white-space: nowrap;
}
.sv-list__filter {
  position: sticky;
  top: 0;
  border-bottom: 1px solid var(--border-inside);
  background: var(--background);
}
.sv-list__filter-icon {
  display: block;
  position: absolute;
  top: calc(2 * var(--base-unit));
  left: calc(2 * var(--base-unit));
}
.sv-list__filter-icon .sv-svg-icon {
  width: calc(3 * var(--base-unit));
  height: calc(3 * var(--base-unit));
}
.sv-list__filter-icon .sv-svg-icon use {
  fill: var(--foreground-light);
}
.sv-list__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  background: var(--background);
  box-sizing: border-box;
  width: 100%;
  border: 1px solid var(--border-inside);
  outline: none;
  font-size: 1em;
  color: var(--foreground, #161616);
  padding: calc(2 * var(--base-unit));
  padding-left: calc(8 * var(--base-unit));
  padding-right: calc(3 * var(--base-unit));
  line-height: calc(3 * var(--base-unit));
}
.sv-list__input::placeholder {
  color: var(--foreground-light);
}
.sv-list__input:focus {
  border: 1px solid var(--primary);
}
.sv-list__input:disabled,
.sv-list__input:disabled::placeholder {
  color: var(--foreground-disabled);
}
.sv-button-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: calc(2 * var(--base-unit));
  height: calc(6 * var(--base-unit));
  overflow: auto;
}
.sv-button-group__item {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  appearance: none;
  width: 100%;
  padding: 11px calc(2 * var(--base-unit));
  line-height: calc(3 * var(--base-unit));
  border: 1px solid var(--border, #d6d6d6);
  outline: none;
  font-size: calc(2 * var(--base-unit));
  font-weight: 400;
  background: var(--background);
  cursor: pointer;
  overflow: hidden;
  color: var(--foreground, #161616);
  position: relative;
}
.sv-button-group__item:not(:first-of-type) {
  margin-left: -1px;
}
.sv-button-group__item--hover:hover {
  background-color: var(--background-dim);
}
.sv-button-group__item-icon {
  display: block;
  height: calc(3 * var(--base-unit));
}
.sv-button-group__item-icon use {
  fill: var(--foreground-light);
}
.sv-button-group__item--selected {
  font-weight: 600;
  color: var(--primary);
}
.sv-button-group__item--selected .sv-button-group__item-icon use {
  fill: var(--primary);
}
.sv-button-group__item--selected:hover {
  background-color: var(--background);
}
.sv-button-group__item-decorator {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.sv-button-group__item-caption {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sv-button-group__item-icon + .sv-button-group__item-caption {
  margin-left: calc(1 * var(--base-unit));
}
.sv-button-group__item--disabled {
  color: var(--foreground-disabled);
  cursor: default;
}
.sv-button-group__item--disabled .sv-button-group__item-icon use {
  fill: var(--foreground-disabled);
}
.sv-button-group__item--disabled:hover {
  background-color: var(--background);
}
.sv-visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
.sv-hidden {
  display: none !important;
}
.sv-title-actions {
  display: flex;
  align-items: center;
  width: 100%;
}
.sv-title-actions__title {
  flex-wrap: wrap;
  max-width: 90%;
  min-width: 50%;
  white-space: initial;
}
.sv-title-actions__bar {
  min-width: 56px;
}
.sv_window {
  position: fixed;
  bottom: 3px;
  right: 10px;
  background-color: cadetblue;
  padding: 1px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto;
  z-index: 100;
}
.sv_window_title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}
.sv_window_content {
  padding: 8px;
  margin: 0;
  max-height: 80vh;
  overflow-y: auto;
}
.sv_window_title a,
.sv_window_title a:link,
.sv_window_title a:visited {
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  color: black;
}
.sjs_sp_controls {
  position: absolute;
  left: 0;
  bottom: 0;
}
.sjs_sp_controls > button {
  user-select: none;
}
.sjs_sp_container > div > canvas:focus {
  outline: none;
}
@media (max-width: 600px) {
  .sd-root-modern .sd-element__num {
    float: none;
    margin-left: 0;
    width: auto;
    padding-left: 0;
    padding-right: 0;
  }
  .sd-root-modern .sd-element__num + span {
    float: none;
    width: auto;
  }
  .sd-root-modern .sd-row--multiple,
  .sd-root-modern .sv-vue-row-additional-div {
    flex-wrap: wrap;
  }
  .sd-root-modern .sd-element__title--expandable:before {
    left: calc(-2.5 * var(--base-unit));
  }
  .sd-root-modern .sd-title.sd-container-modern__title {
    flex-direction: column;
  }
  .sd-root-modern .sd-header__text {
    min-width: 100%;
  }
  .sd-root-modern .sd-multipletext__cell {
    display: block;
  }
  .sd-root-modern .sd-multipletext__cell:not(:last-child) {
    padding-right: 0;
    padding-bottom: calc(2 * var(--base-unit));
  }
  .sd-root-modern .sd-multipletext__item-container {
    flex-direction: column;
  }
  .sd-root-modern .sd-multipletext__item-title {
    font-size: 12px;
    line-height: 16px;
    height: 16px;
    padding-right: 0;
    border-right: none;
    margin-bottom: 0;
    margin-top: calc(1 * var(--base-unit));
    width: 100%;
    max-width: none;
    text-transform: uppercase;
    font-weight: 600;
  }
  .sd-root-modern .sd-multipletext__item {
    width: 100%;
  }
  .sd-root-modern .sd-multipletext__item .sd-input {
    padding-right: 0;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: calc(1 * var(--base-unit));
  }
  .sd-root-modern .sd-selectbase--multi-column {
    flex-direction: column;
  }
  .sd-root-modern
    .sd-selectbase--multi-column
    .sd-selectbase__column:not(:last-child) {
    padding-right: 0;
  }
  .sd-root-modern .sd-question.sd-question--table > .sd-question__content {
    padding-top: 0;
  }
  .sd-root-modern .sd-question--table,
  .sd-root-modern .sd-question--scroll {
    overflow-x: visible;
  }
  .sd-root-modern .sd-table__cell.sd-matrix__cell {
    display: flex;
    align-items: flex-start;
  }
  .sd-root-modern .sd-table__cell.sd-matrix__cell:after {
    content: attr(data-responsive-title);
    display: block;
    margin-left: calc(1 * var(--base-unit));
    padding: calc(1.5 * var(--base-unit)) 0;
    text-align: left;
  }
  .sd-root-modern .sd-table__cell.sd-matrix__cell:first-child {
    padding-top: calc(2 * var(--base-unit));
    padding-bottom: calc(1 * var(--base-unit));
  }
  .sd-root-modern .sd-table thead {
    display: none;
  }
  .sd-root-modern .sd-table tr {
    display: block;
  }
  .sd-root-modern .sd-matrix__table tr + tr {
    margin-top: calc(1 * var(--base-unit));
  }
  .sd-root-modern .sd-table:not(.sd-matrix__table) tr {
    padding-bottom: calc(1 * var(--base-unit));
  }
  .sd-root-modern .sd-table:not(.sd-matrix__table) tr::after {
    z-index: 12;
    content: " ";
    display: block;
    position: relative;
    height: 1px;
    background-color: var(--border-light, #eaeaea);
    left: calc(-2 * var(--base-unit));
    bottom: calc(-1 * var(--base-unit));
    width: calc(100% + 4 * var(--base-unit));
    z-index: 12;
  }
  .sd-root-modern .sd-matrix__label {
    justify-content: start;
  }
  .sd-root-modern .sd-table__cell {
    display: block;
    padding-top: 0;
    padding-bottom: 0;
  }
  .sd-root-modern .sd-table__cell:not(.sd-matrix__cell):not(:first-child) {
    margin-top: calc(1 * var(--base-unit));
  }
  .sd-root-modern
    .sd-table__cell:not(.sd-matrix__cell):not(.sd-table__cell--actions):not(
      .sd-table__cell--row-text
    )::before {
    padding-top: calc(2 * var(--base-unit));
    padding-bottom: calc(1 * var(--base-unit));
    content: attr(data-responsive-title);
    font-weight: 600;
    display: block;
    text-align: left;
  }
  .sd-root-modern .sd-table__cell.sd-table__cell--actions {
    width: auto;
  }
  .sd-root-modern .sd-table__cell.sd-table__cell--actions .sd-action-bar {
    margin-right: calc(-3 * var(--base-unit));
    justify-content: end;
    background: var(--background);
  }
  .sd-root-modern .sd-action.sd-action.sd-matrixdynamic__remove-btn {
    opacity: 1;
    padding: calc(1 * var(--base-unit)) calc(3 * var(--base-unit));
  }
  .sd-root-modern
    .sd-action.sd-action.sd-matrixdynamic__remove-btn
    .sd-action__icon {
    display: none;
  }
  .sd-root-modern .sd-action.sd-action.sd-matrixdynamic__remove-btn:after {
    content: attr(title);
  }
  .sd-root-modern .sd-matrixdynamic__footer {
    padding-top: calc(1 * var(--base-unit));
    margin-bottom: calc(-2 * var(--base-unit));
  }
  .sd-root-modern .sd-table__cell--row-text {
    color: var(--foreground-light);
    padding-top: calc(2 * var(--base-unit));
  }
  .sd-root-modern .sd-matrixdropdown.sd-table tr:not(:last-child) {
    padding-bottom: calc(2 * var(--base-unit));
  }
  .sd-root-modern .sd-matrixdropdown.sd-table tr::after {
    bottom: calc(-2 * var(--base-unit));
  }
  .sd-root-modern .sd-matrixdropdown.sd-table tr:last-child:after {
    content: none;
  }
  .sd-root-modern .sd-matrixdynamic__btn.sd-matrixdynamic__add-btn {
    margin-left: calc(-2 * var(--base-unit));
  }
}
.sd-root-modern ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: transparent;
}
.sd-root-modern ::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  background-clip: padding-box;
  border-radius: 32px;
  background-color: var(--border, #d6d6d6);
}
.sd-root-modern ::-webkit-scrollbar-track {
  background: transparent;
}
.sd-root-modern ::-webkit-scrollbar-thumb:hover {
  border: 2px solid transparent;
  background-color: var(--foreground-light);
}
.sv-popup .sv-popup__scrolling-content {
  scrollbar-width: thin;
  scrollbar-color: var(--border, #d6d6d6) transparent;
}
.sv-popup .sv-popup__scrolling-content::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: var(--background);
}
.sv-popup .sv-popup__scrolling-content::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  background-clip: padding-box;
  border-radius: 32px;
  background-color: var(--border, #d6d6d6);
}
.sv-popup .sv-popup__scrolling-content::-webkit-scrollbar-track {
  background: transparent;
}
.sv-popup .sv-popup__scrolling-content::-webkit-scrollbar-thumb:hover {
  border: 2px solid transparent;
  background-color: var(--foreground-light);
}
.sv-popup.sv-popup--modal {
  padding: calc(4 * var(--base-unit));
}
.sv-popup.sv-popup--modal .sv-popup__container {
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
}
.sv-popup.sv-popup--modal .sv-popup_shadow {
  filter: drop-shadow(
    0px calc(1 * var(--base-unit)) calc(2 * var(--base-unit)) rgba(0, 0, 0, 0.1)
  );
}
.sv-popup.sv-popup--modal .sv-popup__footer {
  padding-bottom: 2px;
}
/* Default V2 m600 */

.sd-root-modern,
.svc-logic-question-value {
  --sd-base-padding: calc(5 * var(--base-unit));
  --sd-base-vertical-padding: calc(4 * var(--base-unit));
}
@media (max-width: 600px) {
  .sd-root-modern,
  .svc-logic-question-value {
    --sd-base-padding: calc(3 * var(--base-unit));
    --sd-base-vertical-padding: calc(2 * var(--base-unit));
  }
}
@media (max-width: 600px) {
  .sd-root-modern .sd-multipletext__cell:not(:last-child) {
    padding-right: 0;
    padding-bottom: calc(2 * var(--base-unit));
  }
  .sd-root-modern
    .sd-selectbase--multi-column
    .sd-selectbase__column:not(:last-child) {
    padding-right: 0;
  }
}
/* Default V2 theme */

.sd-root-modern * {
  scrollbar-width: thin;
  scrollbar-color: var(--border) transparent;
}
/* Sv Action bar */
.sv-action-bar {
  display: flex;
  box-sizing: content-box;
  position: relative;
  align-items: center;
  margin-left: auto;
  padding: 0 calc(2 * var(--base-unit));
  overflow: hidden;
  white-space: nowrap;
}
.sv-action--first {
  margin-right: auto;
  flex: 1;
}
.sv-action--first .sv-action-bar-item {
  flex-direction: row-reverse;
}
.sv-action--first .sv-action-bar-item__title {
  margin-left: 0;
  margin-right: calc(1 * var(--base-unit));
}
.sv-action-bar-separator {
  display: inline-block;
  width: 1px;
  height: 24px;
  vertical-align: middle;
  margin-right: 16px;
  background-color: var(--border, #d6d6d6);
}
.sv-action-bar-item {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  height: calc(5 * var(--base-unit));
  padding: calc(1 * var(--base-unit));
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  line-height: calc(3 * var(--base-unit));
  font-size: calc(2 * var(--base-unit));
  font-family: var(--fontPrimary);
  overflow-x: hidden;
  white-space: nowrap;
}
button.sv-action-bar-item {
  overflow: hidden;
}
.sv-action--hidden {
  width: 0px;
  height: 0px;
  display: none;
}
.sv-action:not(:last-child) > .sv-action__content {
  box-sizing: content-box;
  padding-right: calc(2 * var(--base-unit));
}
/* Sv Button Group */

.sv-button-group__item:not(:first-of-type) {
  margin-left: -1px;
}
.sv-button-group__item-icon + .sv-button-group__item-caption {
  margin-left: calc(1 * var(--base-unit));
}
/* Sv List */

.sv-list {
  padding: 0;
  margin: 0;
  background: var(--background);
  font-family: var(--fontPrimary);
  list-style-type: none;
}
.sv-list__item {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding: calc(1 * var(--base-unit)) calc(2 * var(--base-unit));
  color: var(--foreground, #161616);
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sv-list__container {
  position: relative;
  border: 1px solid var(--slateLight);
  border-top: none;
}
/* Sv Pop-up */

sv-popup {
  display: block;
  position: absolute;
  z-index: -1;
}
.sv-popup__footer-item + .sv-popup__footer-item {
  margin-left: calc(1 * var(--base-unit));
}
/* Sd Action  */

.sd-action {
  appearance: none;
  border: none;
  background: var(--BG-Grey);
  padding: calc(1 * var(--base-unit)) calc(3 * var(--base-unit));
  color: var(--slate);
  border-radius: 0;
  font-weight: 600;
  font-family: var(--fontPrimary);
  font-style: normal;
  font-size: calc(2 * var(--base-unit));
  line-height: calc(3 * var(--base-unit));
  outline: none;
  transition: 0.3s;
}
svg.sd-action--icon {
  fill: var(--foreground-light);
}
.sd-action.sd-action:not(:disabled):not(.sd-action--disabled):hover,
.sd-action.sd-action:not(:disabled):not(.sd-action--disabled):focus-visible {
  outline: none;
  box-shadow: none !important;
  background-color: #c70c2e;
  cursor: pointer;
  opacity: 1;
  color: white;
}
.sd-action.sd-action:not(:disabled):not(
    .sd-action--disabled
  ):hover.sd-action--icon,
.sd-action.sd-action:not(:disabled):not(
    .sd-action--disabled
  ):focus-visible.sd-action--icon {
  background-color: var(--background-dim);
}
.sd-action.sd-action:not(:disabled):not(
    .sd-action--disabled
  ):hover.sd-action--negative,
.sd-action.sd-action:not(:disabled):not(
    .sd-action--disabled
  ):focus-visible.sd-action--negative {
  background-color: var(--red-light, rgba(230, 10, 62, 0.1));
}
.sd-action.sd-action:not(:disabled):not(
    .sd-action--disabled
  ):hover.sd-action--negative.sd-action--icon
  use,
.sd-action.sd-action:not(:disabled):not(
    .sd-action--disabled
  ):focus-visible.sd-action--negative.sd-action--icon
  use {
  fill: var(--red, #e60a3e);
}
.sd-action.sd-action:not(:disabled):not(.sd-action--disabled):hover:active,
.sd-action.sd-action:not(:disabled):not(
    .sd-action--disabled
  ):focus-visible:active {
  opacity: 0.5;
}
.sd-action__icon {
  display: block;
  width: 24px;
  height: 24px;
}
/* Sd Body */

.sd-body {
  min-width: calc(300px + 6 * var(--base-unit));
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.sd-body.sd-body--static {
  max-width: calc(84 * var(--base-unit));
}
/* .sd-body__progress ~ div > .sd-body__page,
.sd-body__progress ~ .sd-body__page {
  margin-top: calc(-4 * var(--base-unit));
} */
/* Sd Boolean */

.sd-boolean input:focus {
  outline: none;
  border: none;
}
.sd-boolean__control:focus ~ .sd-boolean__switch {
  box-shadow: none;
}
.svc-question__content
  .sd-boolean:not(.sd-boolean--checked):not(.sd-boolean--indeterminate)
  .sd-boolean__label:nth-of-type(1),
.svc-question__content .sd-boolean--checked .sd-boolean__label:nth-of-type(2) {
  color: var(--primary);
  font-weight: 600;
  background: var(--background);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: calc(12.5 * var(--base-unit));
}
/* Sd Buttons */

.sd-btn:hover {
  border-color: var(--primary);
  background-color: var(--primary);
}
/* Sd Checkbox */

.sd-checkbox--checked
  .sd-checkbox__control:focus
  + .sd-checkbox__decorator
  .sd-checkbox__svg
  use {
  fill: var(--primary);
}
/* Sd comment */

/* Completed Page */

.sd-completedpage:before,
.sd-completedpage:after {
  display: block;
  width: calc(9 * var(--base-unit));
  margin-left: calc(50% - 36px);
  content: "";
}
/* Sd Context button */

.sd-context-btn:focus-visible use,
.sd-context-btn:hover use {
  fill: var(--primary);
}
.sd-context-btn:focus-visible.sd-context-btn--negative use,
.sd-context-btn:hover.sd-context-btn--negative use {
  fill: var(--red, #e60a3e);
}
/* Sd Description */

.sd-description {
  font-family: var(--fontPrimary);
  font-style: normal;
  font-weight: normal;
  color: var(--foreground);
  white-space: normal;
  word-break: break-word;
  font-size: calc(2 * var(--base-unit));
  padding-top: 1em;
}

.sd-description li {
  list-style-type: none;
  display: flex;
  padding-left: 1.5em;
  margin-bottom: 0.5em;
}

.sd-description li:before {
  content: "";
  display:  inline-flex;
  width: 0;   
  height: 0;   
  border-top: 0.5em solid transparent;  
  border-bottom: 0.5em solid transparent;     
  border-left: 0.75em solid var(--BDO-Grey);
  margin-left: -1.5em;
  margin-right: .75em; 
  margin-top: 0.33em;
}

/* Sd Element */

.svc-logic-question-value,
div:only-child > .sd-element--with-frame:not(.sd-element--collapsed) {
  border-radius: 0;
  box-sizing: border-box;
  padding-left: var(--sd-base-padding);
  padding-right: var(--sd-base-padding);
  padding-bottom: var(--sd-base-padding);
  background: var(--background);
  box-shadow: none;
}
.svc-logic-question-value > .sd-question__erbox--above-question,
div:only-child
  > .sd-element--with-frame:not(.sd-element--collapsed)
  > .sd-question__erbox--above-question {
  border-radius: 0;
  margin-left: calc(-1 * var(--sd-base-padding));
  width: calc(100% + 2 * var(--sd-base-padding));
}
.svc-logic-question-value.sd-question--paneldynamic,
div:only-child
  > .sd-element--with-frame:not(
    .sd-element--collapsed
  ).sd-question--paneldynamic {
  padding-bottom: 0;
}
.svc-logic-question-value,
.sd-element:not(.sd-element--collapsed) {
  padding-top: var(--sd-base-vertical-padding);
}
.sd-element.sd-element--complex {
  padding-top: 0;
}
.sd-element--collapsed.sd-element--with-frame > .sd-element__header {
  box-shadow: none;
  border-radius: 0;
}
.sd-element--collapsed:not(.sd-element--with-frame) > .sd-element__header {
  margin-left: calc(-1 * var(--sd-base-padding));
  width: calc(100% + 2 * var(--sd-base-padding));
}
.sd-element--complex:not(.sd-element--collapsed)
  > .sd-element__header--location-top {
  padding-top: var(--sd-base-vertical-padding);
  padding-bottom: var(--sd-base-vertical-padding);
}
.sd-element--complex:not(.sd-element--collapsed)
  > .sd-element__header--location-top:after {
  content: " ";
  display: block;
  position: relative;
  bottom: calc(-1 * var(--sd-base-vertical-padding));
  height: 1px;
  background: var(--border-light, #eaeaea);
}
.sd-element--complex.sd-element--with-frame
  > .sd-element__header--location-top:after {
  content: " ";
  left: calc(-1 * var(--sd-base-padding));
  width: calc(100% + 2 * var(--sd-base-padding));
}
.sd-element--nested.sd-panel > .sd-element__header--location-top,
.sd-element--nested.sd-question--paneldynamic
  > .sd-element__header--location-top {
  padding-top: calc(0.5 * var(--sd-base-vertical-padding));
  padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));
}
.sd-element--nested.sd-panel:not(.sd-element--collapsed) {
  padding-bottom: var(--sd-base-padding);
}
.sd-element__title {
  font-size: 0;
  line-height: 0;
  position: static;
  font-weight: 600;
  margin: 0;
}
.sd-element__title .sd-element__num {
  font-size: calc(2 * var(--base-unit));
  line-height: calc(3 * var(--base-unit));
  padding-top: 0;
}
.sd-element__title span {
  font-size: calc(2 * var(--base-unit));
  line-height: calc(3 * var(--base-unit));
}
.sd-element__title .sv-string-viewer {
  white-space: normal;
  align-items: center;
  max-width: 100%;
}
.sd-element__num {
  float: left;
  padding: 0;
  width: auto;
  min-width: calc(4 * var(--base-unit));
  font-size: calc(1.5 * var(--base-unit));
  line-height: calc(2 * var(--base-unit));
  color: var(--BDO-Slate);
  margin-left: calc(-4 * var(--base-unit));
  text-align: right;
  box-sizing: border-box;
  white-space: nowrap;
  flex-shrink: 0;
}
.sd-element__num + span {
  float: left;
  width: 0;
}
div:not(:only-child) > .sd-element > .sd-question__header .sd-action-bar,
.sd-element__title--expandable .sd-action-bar {
  margin-right: 0;
}
div:not(:only-child) > .sd-element > .sd-question__header .sd-element__num,
.sd-element__title--expandable .sd-element__num {
  float: none;
  margin-left: 0;
  width: auto;
  padding-left: 0;
  padding-right: 0;
}
div:not(:only-child)
  > .sd-element
  > .sd-question__header
  .sd-element__num
  + span,
.sd-element__title--expandable .sd-element__num + span {
  float: none;
  width: auto;
}
/* Sd File  */

.sd-file .sv-action-bar #fileIndex .sv-action-bar-item:hover {
  background-color: var(--background);
}
.sd-file .sv-action-bar #fileIndex .sv-action-bar-item:disabled {
  opacity: initial;
}
.sd-file .sv-action-bar .sv-action:not(:last-child) > .sv-action__content {
  padding-right: calc(1 * var(--base-unit));
}
.sd-file__choose-btn--icon,
.sd-file__clean-btn {
  top: calc(1 * var(--base-unit));
  position: absolute;
}
.sd-file__preview img:hover + .sd-file__remove-file-button,
.sd-file__preview svg:hover + .sd-file__remove-file-button,
.sd-file__preview .sd-file__remove-file-button:hover {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.sd-file__image-wrapper img:not(.sd-file__default-image) {
  display: block;
  width: 96px;
  height: 96px;
  object-fit: contain;
  background: #f3f3f3;
}
.sd-file--single img:hover + .sd-file__remove-file-button {
  display: none;
}
/* Sd Image  */

/* Image picker  */

@supports not (aspect-ratio: 1/1) {
  .sd-imagepicker > div {
    margin-right: calc(2 * var(--base-unit));
  }
}
/* Sd Input */

.sd-question__content.sd-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: static;
  width: 100%;
  height: calc(6 * var(--base-unit));
  box-sizing: border-box;
  padding: calc(1.5 * var(--base-unit)) calc(2 * var(--base-unit));
  line-height: calc(3 * var(--base-unit));
  font-family: var(--fontPrimary);
  font-size: calc(2 * var(--base-unit));
  color: var(--foreground, #161616);
  background-color: var(--background-dim-light, #f9f9f9);
  box-shadow: inset 0px 0px 0px 1px var(--slateLight);
  border: none;
  border-radius: 0;
}
.sd-input--disabled,
.sd-input--disabled::placeholder {
  color: var(--foreground-disabled);
}
/* Sd Item */

.sd-item--disabled.sd-item--disabled .sd-item__decorator,
.sd-item__decorator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(3 * var(--base-unit));
  height: calc(3 * var(--base-unit));
  box-sizing: border-box;
  box-shadow: 0 0 0 1px var(--Mid-Grey);
  background: var(--background-dim-light, #f9f9f9);
  /* border: 1px solid var(--slateLight); */
  flex-shrink: 0;
}
.sd-item__control:focus + .sd-item__decorator,
.sd-item--allowhover:hover .sd-item__decorator {
  box-shadow: var(--focus);
  border: var(--focus-border);
  outline: none;
  transition: all 0.1s ease-in;
}
.sd-item__control:focus + .sd-item__decorator,
.sd-item--allowhover:hover .sd-item__decorator {
  background: var(--background);
}
.sd-item__control:focus + .sd-radio__decorator,
.sd-item--allowhover:hover .sd-radio__decorator,
.sd-item__control:focus + .sd-checkbox__decorator,
.sd-item--allowhover:hover .sd-checkbox__decorator {
  box-shadow: 0 0 8px rgb(237 26 59 / 34%);
  border: 1px solid var(--primary);
}
/* Sd multiple text */

.sd-multipletext__cell:not(:last-child) {
  padding-right: calc(2 * var(--base-unit));
}
.sd-multipletext__item-container .sd-input,
.sd-multipletext__item-title {
  margin-top: calc(1.5 * var(--base-unit));
  margin-bottom: calc(1.5 * var(--base-unit));
}
.sd-multipletext__item-container:hover:not(:focus-within)
  > .sd-question__erbox--tooltip {
  display: inline-block;
}
.sd-multipletext tr:not(:last-child) .sd-multipletext__cell {
  padding-bottom: calc(2 * var(--base-unit));
}
/* Sd Body */

.sd-body__navigation .sd-btn {
  padding: calc(1.5 * var(--base-unit)) calc(8 * var(--base-unit));
  min-width: 10rem;
}
.sd-body__navigation .sd-btn:last-child {
  background-color: var(--primary);
}
/* Sd Page */

.sd-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 calc(2 * var(--base-unit))
    calc(2 * var(--base-unit));
  width: 100%;
  box-sizing: border-box;
}
/* Sd Panel  */

/* Sd Panel Dynamic */
.sd-paneldynamic__remove-btn {
  float: unset !important;
  margin-right: 0!important;
  margin-bottom: 1rem;
}

.sd-paneldynamic > .sd-panel + .sd-paneldynamic__remove-btn {
  margin-bottom: calc(0.5 * var(--sd-base-vertical-padding));
}
.sd-paneldynamic
  .sd-paneldynamic__panel-wrapper
  > .sd-panel
  > .sd-panel__header
  ~ .sd-panel__content {
  margin-top: 8px;
}
.sd-paneldynamic__footer .sd-paneldynamic__prev-btn,
.sd-paneldynamic__footer .sd-paneldynamic__next-btn {
  display: block;
}
.sd-paneldynamic__footer .sd-paneldynamic__prev-btn svg,
.sd-paneldynamic__footer .sd-paneldynamic__next-btn svg {
  width: calc(2 * var(--base-unit));
  height: calc(2 * var(--base-unit));
}
.sd-paneldynamic__footer .sd-paneldynamic__add-btn,
.sd-paneldynamic__footer .sd-paneldynamic__progress-text,
.sd-paneldynamic__footer .sd-paneldynamic__progress--bottom {
  display: initial;
}
.sd-paneldynamic__prev-btn,
.sd-paneldynamic__next-btn {
  display: none;
  width: 16px;
  height: 16px;
}
.sd-paneldynamic__prev-btn .sv-svg-icon,
.sd-paneldynamic__next-btn .sv-svg-icon {
  display: block;
}
/* Sd Progress buttons */

.sd-progress-buttons__list
  li.sd-progress-buttons__list-element--passed
  + li:after {
  background-color: var(--Success-Green);
}
/* Sd Progress */

/* Sd Question  */

.sd-question__header--location-top {
  padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));
}
.sd-question__content:hover:not(:focus-within) > .sd-question__erbox--tooltip {
  display: block;
}
.sd-question__erbox--location--top,
.sd-question__erbox--location--bottom {
  display: none;
}
.sd-question--complex
  .sd-question__erbox--above-question
  ~ .sd-question__header--location-top {
  padding-top: calc(1 * var(--base-unit));
}
.sd-question__placeholder > div > .sv-string-viewer,
.sd-question__placeholder > span > .sv-string-viewer {
  white-space: pre-line;
}
.sd-radio--checked .sd-radio__control:focus + .sd-radio__decorator:after {
  background-color: var(--primary);
}
/* Sv Ranking */

/* Sd Rating */

.sd-rating__item--allowhover:hover {
  border: none;
  cursor: pointer;
}
.sd-rating__item + .sd-rating__item {
  margin-left: calc(1 * var(--base-unit));
}
.sd-rating__item--selected:focus-within {
  box-shadow: none;
}
.sd-rating__item--allowhover:hover,
.sd-rating__item:focus-within {
  border: none;
}
/* Sd Row */

.sd-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  margin-top: calc(2 * var(--base-unit));
}
.sd-page > .sd-row--multiple,
.svc-row > .sd-row--multiple {
  background: var(--background);
  box-shadow: none;
  border-radius: 0;
}
:not(.svc-row--ghost) > .sd-row {
  min-height: 50px;
}
.sd-row__question {
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
}
/* Sd Select base */

.sd-selectbase__label {
  display: flex;
  position: relative;
}
.sd-selectbase__column:not(:last-child) {
  padding-right: 16px;
}
/* Sj Signiture pad */

.sd-question--signature:not(.sd-question--answered) .sd-signaturepad__controls {
  display: none;
}
/* Sd Table */

.sd-table__cell--actions .sv-action-bar,
.sd-matrixdynamic__add-btn .sv-action-bar {
  overflow: visible;
}
.sd-table__cell--actions .svc-string-editor__button--done,
.sd-matrixdynamic__add-btn .svc-string-editor__button--done {
  width: calc(2 * var(--base-unit));
}
.sd-table__cell--actions:not(.sd-table__cell--vertical) {
  width: 0;
}
.sd-table__question-wrapper:not(:focus-within):hover {
  position: relative;
}
.sd-table__question-wrapper:not(:focus-within):hover
  .sd-question__erbox--tooltip {
  display: inline-block;
}
.sd-table__cell--actions:not(.sd-table__cell--vertical),
.sd-table__cell--empty,
.sd-table__cell--row-text,
.sd-matrix__cell:first-child,
.sd-matrix tr > td:first-child {
  position: sticky;
  background: var(--background);
  z-index: 12;
}
.sd-table__cell--actions:not(.sd-table__cell--vertical):first-child,
.sd-table__cell--empty:first-child,
.sd-table__cell--row-text:first-child,
.sd-matrix__cell:first-child:first-child,
.sd-matrix tr > td:first-child:first-child {
  left: calc(-1 * var(--base-unit));
}
.sd-table__cell--actions:not(.sd-table__cell--vertical):last-child,
.sd-table__cell--empty:last-child,
.sd-table__cell--row-text:last-child,
.sd-matrix__cell:first-child:last-child,
.sd-matrix tr > td:first-child:last-child {
  right: calc(-1 * var(--base-unit));
}
.sd-table__cell--actions:not(.sd-table__cell--vertical):last-child
  .sd-action-bar {
  margin-right: calc(-3 * var(--base-unit));
  justify-content: end;
  background: var(--background);
}
.sd-question--table:not(.sd-element--with-frame) {
  padding-right: var(--sd-base-padding);
  margin-right: calc(-1 * var(--sd-base-padding));
  padding-left: var(--sd-base-padding);
  margin-left: calc(-1 * var(--sd-base-padding));
  box-sizing: content-box;
}
.sd-question--scroll {
  overflow-x: scroll;
}
.sd-root-modern.sd-root-modern--mobile
  .sd-table__cell:not(.sd-matrix__cell):not(:first-of-type) {
  margin-top: calc(var(--base-unit) * 1);
}

@media (max-width: 600px) {
  .sd-root-modern.sd-root-modern--mobile
    .sd-table__cell.sd-table__cell--actions
    .sd-action-bar {
    margin-right: 0;
  }
  .sd-root-modern.sd-root-modern--mobile
    .sd-matrixdynamic__btn.sd-matrixdynamic__add-btn {
    margin-left: 0;
  }
  .sd-root-modern .sd-action.sd-action.sd-matrixdynamic__remove-btn:before {
    content: "-";
    display: inline-flex;
    border: 1px solid var(--primary);
    border-radius: 100%;
    width: 1rem;
    height: 1rem;
    align-items: center;
    justify-content: center;
    margin-right: 0.333rem;
    font-size: 1rem;
    padding-bottom: 3px;
  }
}
/* sd Header */

.sd-title {
  display: block;
  font-family: var(--fontPrimary);
  font-style: normal;
  color: var(--foreground, #161616);
  white-space: normal;
  word-break: break-word;
}
.sd-title.sd-container-modern__title {
  display: flex;
  padding: calc(2 * var(--base-unit));
  border-bottom: none;
  overflow: auto;
  font-family: var(--fontPrimary);
  gap: calc(4 * var(--base-unit));
  min-width: 360px;
}
.sd-header__text h3 {
  padding-bottom: calc(1 * var(--base-unit));
  font-size: calc(4 * var(--base-unit));
  font-weight: 700;
  line-height: 1em;
  color: var(--foreground);
  text-transform: uppercase;
}
.sd-root-modern:not(.svc-tab-designer)
  .sd-container-modern__title
  .sd-header__text
  h3 {
  margin: 0;
}
.sd-root-modern:not(.svc-tab-designer)
  .sd-container-modern__title
  .sd-description {
  margin: 0;
}
/* Bespoke BDO Styling */

html,
body,
[class*="root"] {
  min-height: 100%;
}
body {
  font-size: calc(var(--base-unit) * 2);
  margin: 0;
}
h3 {
  font-size: 1.714em;
}
.sd-question__content h4 {
  font-size: calc(var(--base-unit) * 2);
  font-weight: 600;
}
p {
  font-size: calc(var(--base-unit) * 2);
  line-height: 1.5;
}
p a:link {
  color: var(--BDO-Hyperlink);
}
.sd-navigation__next-btn, .sd-navigation__prev-btn {
  background-color: var(--red)!important;
}
.sd-navigation__next-btn:hover, .sd-navigation__prev-btn:hover {
  box-shadow: none!important;
  background-color: #c70c2e !important;
}
/* .sd-body__navigation .sd-btn:last-child {
  margin-left: auto;
} */
.sd-row__question .sd-html {
  white-space: normal;
}
.sd-element__header.sd-question__header--location--bottom {
  margin-top: calc(var(--base-unit) * 2);
}
.sv-ranking {
}
.sv-ranking-item__index {
  background: rgb(219 225 230 / 50%) !important;
}
.sv-ranking-item__icon {
  fill: var(--slate);
}
input[aria-label*="under input"] {
  margin-bottom: calc(var(--base-unit) * 1);
}
input[type="range"] {
  box-shadow: inset 0px 0px 0px 1px var(--shade);
  -webkit-appearance: none;
  width: 100%;
  height: var(--base-unit);
  outline: none;
  padding: 0;
  appearance: none;
  border: none;
  margin: 0;
  cursor: default;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: var(--base-unit);
  background: var(--slateLight);
}
input[type="range"]:focus {
  box-shadow: none;
  border: inherit;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: calc(var(--base-unit) * 3);
  height: calc(var(--base-unit) * 3);
  border-radius: 100%;
  background: var(--primary);
  cursor: pointer;
  border: 1px solid var(--redDark);
}
input[type="range"]::-moz-range-thumb {
  width: calc(var(--base-unit) * 3);
  height: calc(var(--base-unit) * 3);
  background: var(--primary);
  cursor: pointer;
  border: 1px solid var(--redDark);
}
.sd-body.sd-completedpage + div {
  font-size: 0.95rem;
  margin: 0 auto 5rem;
  display: block;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}
/* https://protect-eu.mimecast.com/s/5d7wCXQyNul0QpOu9pXzM?domain=bdo.co.uk iframe */

body,
html,
[class*="root"],
.sd-root-modern {
  background: transparent;
}
body {
  background-color: #e7e7e7;
}
.sd-root-modern {
  margin: 0 auto;
  max-width: 1140px;
  width: 100%;
  font-family: var(--fontPrimary);
}
.sd-root-modern[style*="width:"] {
  width: 100% !important;
}
.sd-root-modern:not(.svc-tab-designer) .sd-container-modern__title {
  background-color: transparent;
  border-bottom: 1px solid rgb(51 51 51 / 20%);
  padding: calc(var(--base-unit) * 5) calc(var(--base-unit) * 2);
  box-shadow: none;
}
.save-button {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='https://protect-eu.mimecast.com/s/D-5wCVvwLsV9JOgTJkAFn?domain=w3.org' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M14 3h2.997v5H14V3zm9 1v20H1V0h17.997L23 4zM6 9h12V2H6v7zm14 4H4v9h16v-9z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center left 1em;
  background-size: calc(var(--base-unit) * 2.5);
  padding: calc(1.5 * var(--base-unit)) calc(6 * var(--base-unit));
  width: 11rem;
  padding-right: 1rem;
  margin-right: 1rem;
}
/*Controlling the button "Save progress" in the survey to make it looks like the layout is in the same height than the title of the survey*/

#capture-survey {
  position: relative;
}
#survey-actions {
  position: absolute;
  right: 0;
  top: 31px;
}
/*Override fixed with on responsive container*/
.sd-container-modern.sd-container-modern--responsive {
  width: 100%!important;
}
/*Controlling the button "Save progress" in the survey to make it looks like the layout is in the same height than the title of the survey*/

[data-id="save-progress-result"] {
    width: 250px;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    box-shadow: 0px 6px 12px 0px rgb(51 51 51 / 20%);
}
/*Navigation buttons*/

.sd-btn--action:not(:disabled):focus,
.sd-btn--action:not(:disabled):hover {
  color: white;
}
.sd-btn:not(:disabled):focus,
.sd-btn:not(:disabled):hover {
  box-shadow: 0 0 0.333rem 0.15rem rgb(51 51 51 / 0.3);
}
.sd-body__navigation .sd-btn.sd-navigation__complete-btn {
  background-color: var(--Success-Green);
  box-shadow: none!important;
  border: none!important;
}
.sd-navigation__complete-btn:hover {
  background-color: #346648 !important;
  box-shadow: none!important;
  border: none!important;
}
.ant-btn-success {
  color: #fff;
  background: var(--Success-Green);
  border-color: var(--Success-Green);
  text-shadow: 0;
  box-shadow: 0;
}
#sv-nav-next,
#sv-nav-preview,
#sv-nav-complete {
  margin-left: auto;
}
/* sd-dropdown */

.sd-dropdown[disabled] {
  pointer-events: none;
}
.sd-dropdown--empty:not(.sd-input--disabled),
.sd-dropdown--empty:not(.sd-input--disabled) .sd-dropdown__value {
  color: var(--foreground-light, #909090);
}
.sd-dropdown__value {
  font-family: var(--fontPrimary);
  font-size: inherit;
  color: var(--foreground-light, #909090);
  padding-right: 36px;
}
.sd-dropdown_clean-button {
  padding: 0 calc(1 * var(--base-unit));
  position: absolute;
  right: 36px;
  top: 10px;
}
.sd-dropdown_clean-button-svg {
  width: calc(3 * var(--base-unit));
  height: calc(3 * var(--base-unit));
}
.sd-dropdown use {
  fill: var(--foreground-light);
}
.sd-input.sd-dropdown:focus-within {
  box-shadow: var(--focus);
  border: var(--focus-border);
}
.sd-dropdown__filter-string-input {
  border: none;
  outline: none;
  padding: 0;
  font-family: var(--fontPrimary);
  font-size: calc(2 * var(--base-unit));
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  line-height: normal;
  appearance: none;
}
.sd-dropdown--empty:not(.sd-input--disabled)
  .sd-dropdown__filter-string-input::placeholder {
  color: var(--foreground-light);
}
.sd-dropdown__filter-string-input::placeholder {
  font-family: var(--fontPrimary);
  font-size: calc(2 * var(--base-unit));
  color: var(--foreground);
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  line-height: normal;
  appearance: none;
}
/* Matrix buttons */

.sd-matrixdynamic__footer {
  display: flex;
}
.sd-matrixdynamic__add-btn:before {
  content: "+";
  margin-right: 0.3em;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 1em;
}
.sd-matrixdynamic__add-btn {
  display: inline-flex;
}
.sd-matrixdynamic__footer button:last-child {
  margin-left: auto;
  background-color: var(--red);
  color: white;
}
.sd-matrixdynamic__footer button:last-child:hover {
  box-shadow: 0 0 0.333rem 0.15rem rgb(51 51 51 / 30%);
}
/* Mobile layout */

@media (max-width: 600px) {
  .sd-root-modern--mobile > form {
    height: 100%;
  }
  .sd-root-modern--mobile > form,
  .sd-root-modern--mobile .sd-container-modern,
  .sd-root-modern--mobile .sd-body {
    display: flex;
    flex-direction: column;
  }
  .sd-root-modern--mobile .sd-container-modern,
  .sd-root-modern--mobile .sd-body--responsive,
  .sd-root-modern--mobile .sd-body__page {
    flex: 1;
  }
}

.fileUploader {
  min-height: 200px;
   width: 100%;
   height: 100%;
   box-sizing: border-box;
   border: 1px dashed var(--border, #d6d6d6);
 }

 .uploadIcon{
  background-color: #333333;
  width: 35px;
  height: 30px;
  mask-repeat: no-repeat;  
  mask-image: url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 91 91'%3E%3Cpath d='M34.1243 25.7979H41.6297V60.1565C41.6297 60.6034 41.9954 60.969 42.4422 60.969H48.536C48.9829 60.969 49.3485 60.6034 49.3485 60.1565V25.7979H56.8743C57.5547 25.7979 57.9305 25.0159 57.5141 24.4878L46.1391 10.0862C46.0631 9.98911 45.966 9.91057 45.8551 9.85656C45.7443 9.80255 45.6226 9.77448 45.4993 9.77448C45.376 9.77448 45.2543 9.80255 45.1434 9.85656C45.0325 9.91057 44.9354 9.98911 44.8594 10.0862L33.4844 24.4776C33.068 25.0159 33.4438 25.7979 34.1243 25.7979V25.7979ZM82.6711 57.1097H76.5774C76.1305 57.1097 75.7649 57.4753 75.7649 57.9222V73.5628H15.2336V57.9222C15.2336 57.4753 14.868 57.1097 14.4211 57.1097H8.32739C7.88052 57.1097 7.51489 57.4753 7.51489 57.9222V78.0315C7.51489 79.8292 8.96724 81.2815 10.7649 81.2815H80.2336C82.0313 81.2815 83.4836 79.8292 83.4836 78.0315V57.9222C83.4836 57.4753 83.118 57.1097 82.6711 57.1097Z'/%3E%3C/svg%3E" );  
 }

 .dropAreaContainer{
  display:flex;
  justify-content: center;
 }

 .dropAreaText{
  margin-top: 5px;
  color: black;
  font-size: medium !important;  
 }

 .uploadAreaInfo{
  color: gray !important;
 }

 .filepond--root {
  font-family: -apple-system, BlinkMacSystemFont,'ProximaNova', 'Segoe UI', Roboto, Helvetica, Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
 
.filepond--file-info .filepond--file-info-main {
  font-size: 14px;
}

.filepond--file-info .filepond--file-info-sub {
  opacity: .75;
  font-size: 12px;
}

.filepond--file-status .filepond--file-status-main {
  margin-top: 3px;
  font-size: 14px;
  line-height: 1.2;
}

.filepond--file-status .filepond--file-status-sub {
  font-size: 12px;
  opacity: .75;
  transition: opacity .25s ease-in-out;
}

.filepond--item-panel {
  background-color: var(--Success-Green);
  border-radius: 0 !important;
}

[data-filepond-item-state='processing-complete'] {
  .filepond--item-panel {
      background-color: var(--Success-Green);
  }
}

[data-filepond-item-state*='invalid'],
[data-filepond-item-state*='error'] {
  .filepond--item-panel {
      background-color: var(--burgundy);
  }
} 